.fantasy-collapsible-component-container {
    border: 1px solid $color-border;
    margin-bottom: 15px;
}

.fantasy-collapsible-component {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;

    &__message {
        padding-left: 8px;

        &__hint {
            font-size: 12px;
            font-style: italic;
            color: #5c626e;
            cursor: initial;
        }
    }

    &__content {
        padding: 10px;
        font-size: 16px;
    }
}
