.rdt_TableHeader.bkNkVs {
    padding-left: 0;
}

.rdt_TableHeadRow {
    text-align: center;
}

.rdt_TableHeadRow {
    text-align: center;
}

.rdt_TableCol:first {
    padding-left: 15px;
    margin-top: 16px;
}

.rdt_Table {
    border: 1px solid $color-border;
}

.rdt_Pagination {
    color: rgba(0, 0, 0, 0.7) !important;
}

.rdt_TableRow {
    height: 60px;
    text-align: center;
}

.rdt_TableCell {
    padding-right: 0 !important;
    padding-left: 0 !important;
    text-align: center !important;
}

.rdt_TableCol {
    div {
        white-space: break-spaces;
        text-overflow: initial;
        overflow: initial;
    }
}

.rdt_TableRow {
    height: 65px;
}
.rdt_TableCell div,
.rdt_TableCol div {
    text-align: center !important;
    margin: auto !important;
    white-space: initial;
}

.rdt_TableCell div,
.rdt_TableCol div {
    margin: auto;
    font-size: 13px;
}

.MuiList-root.MuiMenu-list {
    max-height: 450px;
}

.button-material {
    @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
    }
}

.MuiDataGrid-footerContainer {
    p {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .MuiDataGrid-footerContainer {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .MuiTablePagination-toolbar {
        padding-left: 0 !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
        gap: 16px !important;
    }
    .MuiPagination-root {
        margin-left: 0 !important;
    }

    .MuiTablePagination-input {
        display: block !important;
    }

    .MuiTablePagination-select {
        display: flex !important;
        gap: 4px !important;
        flex-direction: row !important;
    }
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}
