.pl-0 {
    padding-left: 0 !important;
}

.p-10 {
    padding: 10px;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.p-0 {
    padding: 0;
}

.ml-5 {
    margin-left: 5px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-m-25 {
    @media screen and (max-width: 758px) {
        margin-bottom: 15px;
    }
}

.w-100 {
    width: 100%;
}

.d-flex-center {
    @include center-content-flex;
}

.analitica-box-shadow {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
}

.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &__image {
        margin-bottom: 3px;
    }

    &__text {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        text-align: center;

        @media screen and (min-width: 600px) {
            font-size: 14px;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: #ebebeb;
    margin: 20px 0;
}

.af-card {
    box-shadow: 0 1px 2px 1px #e1e1e1 !important;
    border-radius: 2px !important;
    margin-bottom: 12px;
    background-color: #fff;

    &__head {
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        padding: 6px 10px;
        background-color: #f9f9f9;
        line-height: 22px;
        height: 34px;
        border-bottom: 1px solid #ebebeb;
    }

    &__head-text {
        font-size: 16px;
        text-transform: uppercase;
        color: #383838;
        font-weight: 600;
    }

    &__head-link {
        font-size: 16px;
        color: $color-clickable-link;
        font-weight: 400;
        cursor: pointer;
    }

    &__body {
        background: #fff;
    }
}

.text-12-16 {
    font-size: 12px;

    @media screen and (min-width: $breakpoint-md) {
        font-size: 16px;
    }
}

.text-14-16 {
    font-size: 14px;

    @media screen and (min-width: $breakpoint-md) {
        font-size: 16px;
    }
}

.custom-table {
    .publift-ads-flash-incontent-1,
    .publift-ads-flash-incontent-2 {
        @media screen and (max-width: $breakpoint-sm) {
            justify-content: flex-start;
        }
    }
}
