$height: 10px;

.multiple-progress-bar-section {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-radius: 4px;

    .game-stats-teams-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $menu-sub-section;
        padding: 5px 10px;
        .game-stats-teams {
            font-size: 14px;

            .game-stats-teams-image {
                width: 30px;
                height: 30px;
            }
        }
    }

    .game-stats-header-section {
        background-color: $color-navigation-menu;
        color: $color-white;
        border-radius: 4px;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .game-stats-header {
        font-size: 18px;
        margin-bottom: 0;
    }
}

.multiple-progress-bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;

    .progress-bar-caption {
        font-size: 14px;
    }

    .progress-bar-container {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $height;

        .progress-bar-left-side-number {
            width: 50px;
            text-align: center;
            font-size: 14px;
        }

        .progress-bar-left-side {
            width: calc((100% - 100px) / 2);
            height: $height;
            display: flex;
            justify-content: flex-end;
            background: $progress-bar-neutral;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            .progress-bar-left-side-area {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                background-color: $progress-bar-left;
                height: $height;
            }
        }

        .progress-bar-right-side {
            width: calc((100% - 100px) / 2);
            height: $height;
            display: flex;
            justify-content: flex-start;
            background: $progress-bar-neutral;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            .progress-bar-right-side-area {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                background: $progress-bar-right;
                height: $height;
            }
        }
        .progress-bar-right-side-number {
            width: 50px;
            text-align: center;
            font-size: 14px;
        }
    }
}

.doughnut-component-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    .doughnut-component-title {
        font-size: 16px;
    }

    .alternative-text{

        @media screen and (max-width: 959px) and (min-width: 599px) {
            padding-left: 0;
            margin-bottom: 60px;
        }
            
        }
    }

