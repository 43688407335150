.af-row {
    display: flex;
    flex-wrap: wrap;

    .af-col-6 {
        width: 50%;
    }

    .af-col-6-left {
        border-right: 1px solid $color-border;
    }
}

.mb-16 {
    margin-bottom: 16px;
}
