.mis-plantillas-icon {
    padding: 5px;
    margin-right: 8px;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    font-size: 10px;
    color: #fff;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
        transform: scale(1.1);
    }
}

.mis-plantillas-icon-edit {
    background: #01aa4d;
}

.mis-plantillas-icon-delete {
    background: #be1212;
}

.mis-plantillas-icon-analysis {
    background: $color-header-section;
}

.mis-plantillas-icon-market {
    background: #69b59f;
}

.mis-plantillas-container .cta-button {
    text-align: center;
}

.dropdown-actions-mis-plantillas {
    .dropdown-menu {
        display: none;
    }
}

.valora-plantilla__image-card-table-nickname {
    top: -7px;
}

.dropdown-actions-mis-plantillas {
    .dropdown-menu.show {
        display: block;
        width: 225px;
        left: -95px !important;
    }

    .dropdown-divider {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    a.dropdown-item {
        font-size: 16px;
        padding-right: 0;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
            background: #0172aa;
            color: #fff;
        }
    }
}

.btn.btn-secondary.dropdown-toggle {
    padding: 3px 6px 3px 10px;
}

.dropdown-toggle::after {
    content: '';
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    z-index: 99999 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width: 900px) {
    .dropdown-actions-mis-plantillas {
        .dropdown-menu.show {
            left: -45px !important;
        }
    }
}

.analitica-fantasy-input {
    border-radius: 4px;
    padding: 6px 12px;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    width: 100%;

    &:focus {
        outline: none;
    }
}

.crear-plantilla-player-image {
    width: 35px;
    height: 35px;
    margin-right: 5px;
}

.crear-plantilla-team-image-container {
    position: relative;
    top: 3px;
}

.crear-plantilla-team-image {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-bottom: 8px;
    position: relative;
    top: 5px;
}

.crear-plantilla-table-container {
    width: 100%;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px;

    tr {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        height: 45px;

        th:first-child {
            padding-left: 10px;
        }

        td:first-child {
            padding-left: 10px;
        }
    }
}

.crear-plantilla-container {
    min-height: calc(100vh - 180px);
}

.crear-plantilla-contanier {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.crear-plantilla-player-position {
    padding: 4px;
    color: #fff;
    float: none;
    font-size: 10px;
}

.nueva-plantillas-btn {
    width: 180px;
}

@media screen and (max-width: 600px) {
    // .analitica-fantasy-input {
    //     width: 100%;
    // }

    .cta-button {
        width: 100%;
    }

    .crear-plantilla-button-contanier {
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .crear-plantilla-container {
        h1 {
            margin-top: 15px;
        }
    }

    .nueva-plantillas-btn {
        width: 100%;
    }
}

.create-template-link-container {
    margin-bottom: 10px;

    .create-template-link {
        color: #1976d2;
        text-decoration: underline;

        .create-template-icon {
            width: 12px;
            margin-right: 2px;
        }
    }
}
