.install-app {

    @media (min-width: 400px) {
        max-width: 390px;
        margin: auto;
    }

    &__index {
        border-radius: 4px;
        border: 1px solid $border-color;
        margin-bottom: 16px;
    }

    &__list {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__item-link {
        color: $color-clickable-link;
        text-decoration: underline;
    }

    &__os-title {
        margin-top: 8px;
        text-align: left;
    }

    &__os-subtitle {
        font-size: 18px;
    }
}