.estadisticas-globales-container {
    min-height: calc(100vh - 181px);

    &__content {
        margin-bottom: 15px;

        &__players {
            width: 100%;
        }
    }

    &__filters {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        padding: 20px 0 0 0;
    }
}

.estadisticas-container {
    margin-top: 25px;
}

.leader-container {
    display: inline-flex;
    margin-top: 40px;
    float: right;
}

.leader-container h2 {
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    top: 6px;
}

.leader-container-selector {
    width: fit-content;
}

.header-stats-page {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1.6;
}

.cBZXUy div:first-child {
    overflow: inherit;
}

.image-card-table {
    width: 40px;
    min-width: 40px;
    height: 43px;
}

.image-card-table-container {
    float: left;
    min-height: 50px;
    min-width: 40px;
}

.image-card-table-nickname {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    white-space: nowrap;
}

.table__total_goals {
    color: #be1212 !important;
}

.leaders-container {
    margin: auto;
    width: 965px;
    margin-bottom: 50px;
}

.jugadores-position-container {
    width: 28px;
    text-align: center !important;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;

    span {
        color: #fff;
        font-size: 10px;
    }
}

.search-table-container .journey-container-selector {
    margin-top: 0;

    .css-yk16xz-control {
        // width: 200px;
    }

    .css-2b097c-container {
        // width: 200px;
    }
}

.leaders-container {
    font-size: 13px;

    .search-table-container .search-table-container__input {
        height: 38px;
        font-size: 13px;
    }
}

.go-to-see-player-stats {
    color: rgba($color: #222222, $alpha: 1);
}

.search-table-container-filter {
    p {
        margin-bottom: 6px;
    }
}

.search-table-container-filters {
    display: inline-flex;

    .search-table-container-filter {
        width: fit-content;
        margin-right: 50px;
    }
}

.search-table-container-mb-25 {
    margin-bottom: 25px !important;
}

.position-color-green {
    background-color: #1a892d;
    border-radius: 4px;
    max-width: 35px;
    float: right;
}

.position-color-red {
    background-color: #be1212;
    border-radius: 4px;
    max-width: 35px;
    float: right;
}

.position-color-blue {
    background-color: #1d6fb1;
    border-radius: 4px;
    max-width: 35px;
    float: right;
}

.position-color-purple {
    background-color: #812c7c;
    border-radius: 4px;
    max-width: 35px;
    float: right;
}

.position-color-black {
    background-color: rgb(15, 15, 15);
    border-radius: 4px;
    max-width: 35px;
    float: right;
}

.position-color-orange {
    background-color: #807816;
    border-radius: 4px;
    max-width: 35px;
    float: right;
}

.sc-hKFyIo {
    text-align: left !important;
    padding-left: 0 !important;
}

.filter-price-rage-button {
    width: fit-content;
    background: #0172aa;
    color: #fff;
    padding: 9px 15px;
    border-radius: 4px;
    float: right;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.filter-price-rage-button-super-container {
    margin-top: 50px;
}

.price-range-input {
    width: 120px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 38px;

    &:focus {
        border: 2px solid #0070f3;
        outline: none !important;
    }
}

.price-range-input-min {
    margin-right: 7px;
}

.price-range-input-max {
    margin-left: 7px;
}

.price-range-input-divider {
    color: rgba(0, 0, 0, 0.2);
}

.rdt_TableCell div,
.rdt_TableCol div {
    margin: auto;
    font-size: 14px;

    @media screen and (min-width: $breakpoint-sm) {
        font-size: 16px;
    }
}

.estadisticas__image-card-table-nickname {
    font-size: 12px;
}

.temporada-select-container {
    float: right;
    margin-bottom: 50px;
}

@media screen and (min-width: 961px) {
    .leaders-container {
        min-height: calc(100vh - 140px);
    }
}

@media screen and (max-width: 1350px) {
    .leaders-container {
        margin: auto;
        width: calc(100vw - 30px);
        min-height: calc(100vh - 140px);
    }

    .mercado-container {
        min-height: calc(100vh - 140px);
    }
}

@media screen and (min-width: 1000px) {
    .leaders-container {
        margin: auto;
        // width: 1250px;
        width: calc(100% - 150px);
    }
}

@media screen and (max-width: 600px) {
    .filter-price-rage-button-container {
        margin-left: 15px;
        margin-right: 0px;
    }

    .leader-container {
        display: block;
        margin-top: 40px;
        float: left;
        text-align: left;

        h2 {
            margin-bottom: 15px;
        }
    }

    .filter-price-rage-button {
        float: left;
    }

    .filter-price-rage-button-parent {
        margin-top: 32px;
        padding-left: 0;
    }
}
