.fantasy-calendar-table-container {
    overflow-x: auto;
    width: 100%;

    .niveles-de-riesgo-container {
        .niveles-de-riesgo-box {
            .collapse.show {
                margin-bottom: 15px;
            }
        }

        .niveles-de-riesgo {
            color: $base-color;
            font-size: 14px;
            text-decoration: none;
        }
    }

    table {
        tr {

            td,
            th {
                border: 1px solid $color-border;
                padding: 0;
                width: 150px;

                &:first-child {
                    width: 50px;
                }
            }

            th {
                height: 50px;
            }
        }
    }
}

.risk-item-container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 961px) {
        flex-direction: column;
    }

    .risk-item {
        display: flex;
        margin-bottom: 5px;

        .risk-color {
            border: 1px solid $color-border;
            width: 40px;
            border-radius: 4px;
            margin-right: 5px;
        }

        .risk-test {
            width: 100%;
        }
    }
}

.fantasy-calendar-table {
    border: 1px solid $border-color;
    border-radius: 4px;

    @media screen and (min-width: 768px) {
        display: table;
    }
}

td,
th {
    font-size: 13px;
}

.player-data_image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-content {
    border-radius: 4px;
    width: 80px;
    margin: auto;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        opacity: 0.9;
    }
}

.risk-very-high {
    color: $color-risk-very-high;
}

.risk-high {
    color: $color-risk-high;
}

.risk-moderate {
    color: $color-risk-medium;
}

.risk-low {
    color: $color-risk-low;
}

.risk-very-low {
    color: $color-risk-very-low;
}

.id-number {
    padding-top: 40px !important;

    @media screen and (max-width: 415px) {
        padding-top: 34px;
    }
}

.new-style-calendar-fantasy {
    .icon-calendar-fantasy {
        display: flex;
        justify-content: flex-end;
    }

    .image-risk-calendar-fantasy {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .image-calendar-fantasy {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .risk-calendar-fantasy {
            @extend .image-calendar-fantasy;

            p {
                margin: 0;
            }
        }
    }
}

.fantasy-calendar-card {
    @include flexbox(center, center, column);

    &__home-marker {
        width: 100%;
        @include flexbox(center, center, row);
        padding: 8px 0;
        gap: 4px;
    }

    &__team {
        @include flexbox(center, center, column);
        padding: 8px 0;
        gap: 8px;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        width: 100%;
    }

    &__risk {
        @include flexbox(center, center, row);
        padding: 8px 0;
        width: 100%;
        gap: 4px;
    }
}