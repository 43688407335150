.desktop-navigation-container {
    background: rgb(34, 39, 47);
    height: $navigation-height;
    z-index: 999;
    position: sticky;
    top: 30px;
    padding: 0 8px;
    border-bottom: 1px solid $border-white;
    width: 100%;

    .image-logo {
        padding-left: 0;
    }

    &__calendar {
        position: absolute;
        right: 8px;
    }

    &__logo-container {
        position: absolute;
        left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__subcontainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        &__menu-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &__list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                padding-left: 0;
                width: 100%;

                &__menu-item {
                    color: white;
                    font-size: 14px;
                    justify-content: center;
                    cursor: pointer;
                    list-style: none;

                    a {
                        &:hover {
                            color: white;
                            text-decoration: none;
                        }
                    }

                    &__submenu {
                        position: relative;
                        font-size: 14px;

                        &__dropdown-menu {
                            position: absolute;
                            margin-top: -15px;
                            margin-left: -5px;
                            padding-left: 0;
                            padding-right: 0;
                            background: $color-white;

                            .dropdown-item {
                                padding: 10px;
                                background: $base-color;
                                color: $font-primary;
                                display: flex;
                                align-items: center;

                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        /* remove the gap so it doesn't close */
    }

    .navigation-dropdown-toggle {
        background: none;
        color: #fff;
        border: none;
    }

    .dropbtn {
        background: transparent;
        color: white;
        padding: 16px;
        font-size: 16px;
        border: none;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .new-sections-desktop {
        border: none;
        border-radius: 30px;
        padding: 1px 7px;
        background-color: #656af1;
        position: absolute;
        right: 32px;
        top: 7px;
        font-size: 10px;

        &:hover {
            color: #fff !important;
            opacity: 0.9;
        }
    }

    .dropdown-content {
        display: none;
        position: absolute;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown-content a {
        color: black;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        background-color: #ddd;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
}

.icon-partidos-drawer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
        cursor: pointer;
    }

    .icon-partidos-drawer {
        color: #fff;
    }

    .icon-partidos-drawer-text {
        color: #fff;
        margin-bottom: 0;
    }
}

.pujaid {
    flex-wrap: nowrap;
}
