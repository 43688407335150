.colaboradores-analitica-fantasy-section {
    &__header {
        font-size: 22px;
        margin-bottom: 24px;
    }
    &__bio {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $border-color;
    }

    &__bio-card {
        width: 100%;
        display: flex;
        gap: 6px;
        border-top: 1px solid $border-color;
        padding-top: 8px;
    }

    &__bio-card-title {
        font-size: 20px;
    }

    &__bio-card-image {
        border-radius: 50%;
    }

    &__bio-text {
        width: 100%;
        border-top: 1px solid $border-color;
        padding-top: 8px;
        margin-top: 8px;
    }

    .colaboradores-container {
        &__row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
        }

        &__header {
            font-size: 20px;
            font-weight: 600;
        }

        &__card {
            display: flex;
            flex-direction: column;
            height: 350px;
            align-items: center;
            box-shadow: $box-shadow-main;
            border-radius: 5px;
            transition: transform 0.3s;
            cursor: pointer;
            justify-content: space-between;
            margin-bottom: 16px;
            padding: 0 8px;
            width: 250px;

            &__name {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                border-bottom: 1px solid $border-color;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 0;
                color: #0284c7;
            }

            &__social-networks {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                width: 100%;
                flex-wrap: wrap;
                border-top: 1px solid $border-color;
                padding: 8px 0;
            }
        }

        &__footer {
            border-top: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #0284c7;
            width: 100%;
            height: 50px;
        }

        &__card:hover {
            transform: scale(1.05);
        }
    }
}
