.only-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.only-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
}

[id^='div-gpt-ad'] {
    display: flex;
    justify-content: center;
    align-items: center;
}

.publift-ads {
    @include center-content-flex;
    padding: 5px 0;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;

    // background-color: red;
    // min-height: 100px;
    // width: 100%;
}

.publift-ads-header {
    min-height: 100px;
}

.analitica-premium {
    .publift-ads-header {
        min-height: 0;
    }
}

.publift-ads-flash-incontent-5,
.publift-ads-flash-incontent-6 {
    @media (max-width: $breakpoint-md) {
        justify-content: flex-start;
    }
}

.publift-ads-mobile-incontent,
.publift-ads-mobile-incontent-1 {
    @media screen and (min-width: $breakpoint-sm) {
        display: none;
    }
}

.analitica-free {
    .ad-unit-head {
        min-height: 250px;
    }

    #div-gpt-ad-1407836169909-0,
    #div-gpt-ad-1407836240192-0 {
        min-height: 250px;
    }

    .lazy-ad-unit {
        min-height: 250px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#div-gpt-ad-1407836037948-5 {
    margin-top: 16px;
}

.url-iniciar-sesion,
.url-crear-cuenta {
    #div-gpt-ad-1407836240192-0 {
        display: none;
    }

    .lazy-ad-unit {
        min-height: 0;
    }
}

.main-ad-unit {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

[id^='af_digitalgreen'] {
    display: flex;
    justify-content: center;
    align-items: center;
}

// .lazy-ad-unit {
//     min-height: 250px;
//     width: 100%;
//     background-color: red;
// }

#div-gpt-ad-yieldlove-sticky-35139 {
    display: none !important;
    position: relative !important;
    width: 0 !important;
    height: 0 !important;
}
