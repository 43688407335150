.modal-header {
    display: inline-flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color: #222222, $alpha: 0.1);
    width: 100%;
    h2 {
        font-size: 21px;
        color: rgba(0, 0, 0, 0.75);
    }
    i {
        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }
}

.position-modal-color-green {
    background-color: #1a892d;
    padding: 4px 5px 10px 6px !important;
}
.position-modal-color-red {
    background-color: #be1212;
    padding: 3px 5px 12px 6px !important;
}
.position-modal-color-blue {
    background-color: #1d6fb1;
    padding: 3px 5px 10px 5px !important;
}
.position-modal-color-purple {
    background-color: #812c7c;
    padding-top: 4px;
    padding-left: 4px;
    margin: auto;
}

.players-list-container {
    overflow-y: auto;
    height: 632px;
}

.player-stats-info__nickname {
    h6 {
        font-size: 14px;
    }
}

.player-stats-info__price {
    font-size: 14px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #222222, $alpha: 0.7);
}

.modal-body {
    width: 100%;

    .modal-body__player-card-container {
        width: 100%;
        display: inline-flex;
        margin-bottom: 10px;
        padding-top: 0px;
        padding-bottom: 10px !important;
        padding-right: 10px;
        border-bottom: 1px solid $color-border;
        padding-bottom: 0px;
        &:hover {
            cursor: pointer;
            background-color: rgba($color: #222222, $alpha: 0.03);
        }
    }

    .team-position-container {
        width: 40px;
    }

    .player-stats-container {
        width: 60px;
        img {
            border-radius: 50%;
        }
    }

    .player-circle-position-1 img {
        border: 1px solid #1d6fb1 !important;
    }

    .player-circle-position-2 img {
        border: 1px solid #1a892d !important;
    }

    .player-circle-position-3 img {
        border: 1px solid #812c7c !important;
    }

    .player-circle-position-4 img {
        border: 1px solid #be1212 !important;
    }

    .team-position-container__image {
        width: 25px;
        position: relative;
        left: 7px;
        height: 28px;
        margin-bottom: 0 !important;
    }

    .team-position-container__position {
        border-radius: 4px;
        color: #fff;
        width: 22px;
        height: 22px;
        font-size: 9px;
        font-weight: bold;
        margin-left: 11px;
    }

    .player-stats-container {
        img {
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
        }
    }

    .team-position-container__image-container {
        // width: 26px;
        margin: auto;
        margin-bottom: 5px;
    }

    .player-stats-info {
        width: 120px;

        .player-stats-info__nickname {
            margin-bottom: 5px;
            h3 {
                font-size: 14px;
                color: #25303d;
                margin-bottom: 0;
            }
        }

        .player-stats-info__price {
            font-size: 14px;
            font-weight: bold;
            color: rgba($color: #222222, $alpha: 0.7);
            margin-bottom: 4px;
        }

        .player-stats-info__status {
            font-weight: bold;
            font-size: 15px;
        }
    }
    .player-stats-score {
        width: calc(100% - 220px);
        text-align: right;
    }
}

.modal-footer {
    width: 100%;
}

.player-status-green {
    color: rgb(26, 137, 45);
}

.player-status-red {
    color: rgb(202, 19, 19);
}

.player-status-orange {
    color: rgb(172, 117, 16);
}

.player-status-yellow {
    color: rgb(170, 63, 99);
}

@media screen and (min-width: 651px) {
    .custom-modal-container {
        top: 540px;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        width: 600px;
        max-height: 800px;
        border-radius: 4px;
        position: absolute;
        background: #fff;
        z-index: 99999999999999999;
        &:active,
        &:focus {
            outline: none;
        }
    }

    .overlay {
        width: 100vw;
    }
}

.close-icon-x {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.7);
    position: relative;
    left: 20px;
    top: 2px;
}

@media screen and (max-width: 650px) {
    .modal-body {
        .modal-body__player-card-container {
            padding-left: 5px;
        }
    }

    .modal-body .team-position-container__position {
        margin-left: 4px;
    }

    .modal-body {
        padding-right: 0;
        padding-left: 0;

        .team-position-container__image-container {
            height: 25px;
            position: relative;
        }

        .player-stats-info__status {
            font-size: 14px;
        }

        .player-stats-info .player-stats-info__price {
            margin-bottom: 2px;
        }

        .player-stats-info .player-stats-info__nickname {
            margin-bottom: 2px;
        }
    }

    .modal-body .team-position-container__image {
        width: 24px;
    }

    .modal-body {
        .team-position-container__position {
            color: #fff;
            width: 24px;
            padding: 4.5px;
            font-size: 10px;
            font-weight: bold;
        }

        .player-stats-info__price {
            font-size: 14px;
        }
    }

    .modal-body {
        .player-stats-container img {
            width: 50px;
            min-width: 50px;
            height: 50px;
            min-height: 50px;
        }
    }
    .modal-body .player-stats-info {
        .player-stats-info__nickname h3 {
            font-size: 14px;
            white-space: nowrap;
        }

        .player-stats-info__price {
            font-size: 14px;
        }
    }

    .team-position-container__image {
        width: 25px;
    }

    .custom-modal-container {
        left: 15px;
        width: calc(100vw - 30px);
        position: absolute;
        z-index: 99999999999999999;
        background-color: #fff;
        max-height: 664px;
        top: 145px;
        margin-right: 0;
        padding-right: 0;
        border-radius: 4px;
        &:active,
        &:focus {
            outline: none;
        }
    }

    .overlay {
        width: 100vw;
    }

    .players-list-container {
        overflow-y: auto;
        height: 500px;
    }

    .ReactModalPortal {
        &:active {
            outline: none;
        }

        &:focus {
            outline: none;
        }
    }
}
