.standing {
    &__team {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__team-name {
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
    }

    &__team-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__group {
        padding: 8px !important;
        background: #f9f9f9 !important;
        display: flex;
        align-items: center;
        border-radius: 4px;
        margin-bottom: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__title {
        font-size: 18px;
        color: #000;
        margin-bottom: 0;
        margin-left: 4px;
    }

    &__table-container {
        overflow-x: auto !important;
        padding: 0 !important;
    }

    &__legend-title {
        padding: 0 !important;
        font-size: 18px;
    }

    &__legend-item {
        padding: 0 !important;
        font-weight: 400;
    }

    table {
        tr td {
            vertical-align: middle;
        }
    }
}

.promotion-champions {
    border-left: 5px solid $color-jornada-dark-blue;
}

.promotion-europa {
    border-left: 5px solid $color-jornada-green;
}

.promotion-conference {
    border-left: 5px solid rgb(255, 167, 38);
}

.relegation {
    border-left: 5px solid $color-jornada-red;
}

.team-streak {
    display: flex;
    gap: 2px;

    &__game {
        font-size: 10px;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 0;
    }

    &__W {
        background: $color-success-500;
    }

    &__L {
        background: $warning-color;
    }

    &__D {
        background: $remember-color;
    }
}
