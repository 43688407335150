.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    height: 52px;

    @media screen and (max-width: 500px) {
        height: 41px;
    }
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
    height: 52px;
    background-color: $primary-color;
    color: $color-white;
    height: 52px;
}

.react-tabs__tab--disabled {
    color: rgb(172, 158, 158);
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.tablist {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.react-tabs__tab-list {
    border-bottom: none;
}

.react-tabs__tab {
    border: 1px solid $color-border;
    border-radius: 4px;
    margin-bottom: 0px;
    width: 100px;
}

.react-tabs__tab-list {
    &::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    -ms-oveflow-style: 'none';
    scrollbar-width: 'none';
}

.fixture-lineups-mobile {
    .react-tabs__tab-list {
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .react-tabs__tab {
            width: 50%;
            @include center-content-flex;
        }

        .react-tabs__tab--selected {
            background: $color-success-500;
            color: $color-white;
        }
    }
}

.fixture-lineups-mobile {
    .react-tabs__tab {
        height: 40px;
    }
}

.main-tabs-content {
    padding-top: 8px;
}

.fixture-rounds-tabs {
    .react-tabs__tab-list {
        display: flex;
        margin-bottom: 0;

        justify-content: space-between;
        background: rgb(34, 39, 47);

        @media screen and (min-width: $breakpoint-sm) {
            justify-content: center;
        }
    }

    .react-tabs__tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        height: auto;
        opacity: 0.7;
    }

    .react-tabs__tab--selected {
        border-radius: 0;
        background: none;
        border-bottom: 3px solid #1976d2;
        opacity: 1;
    }
}

.af-tab-list {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: #22272f;
    width: 100%;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .af-tab {
        width: 100px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        opacity: 0.7;
        gap: 4px;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        cursor: pointer;

        .af-tab-text {
            font-size: 13px;
            margin-bottom: 0;
            color: #fff;
        }
    }

    .af-tab-selected {
        opacity: 1;
        border-bottom: 3px solid #1976d2;
    }
}

.af-tab-list.af-secondary-tab-list {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 16px;
    border-radius: 4px;
    height: 40px;

    .af-tab {
        .af-tab-text {
            color: #000;
        }
    }
}

.af-tab-list.af-light-tab-list {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #f5f7f9;
    margin-top: 16px;
    border-radius: 4px;
    height: 55px;

    .af-tab {
        .af-tab-text {
            color: #000;
        }
    }
}

.secondary-rounds-tabs {
    .react-tabs__tab-list {
        display: flex;
        margin-bottom: 0;
        justify-content: space-between;
        background: #fff;
    }

    .react-tabs__tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        height: auto;
        width: 100%;
    }

    .react-tabs__tab--selected {
        border-radius: 0;
        border-bottom: 3px solid #1976d2;
        opacity: 1;
        color: #000;
    }

    &__text {
        font-size: 12px !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden !important;
    }
}

.full-calendar {
    color: $color-clickable-link;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background: #fff;
    width: 100%;
    margin: 8px 0;
}
