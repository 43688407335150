.player-market-card_image {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    margin: 10px 10px 0 10px;
}

.mercado__image-card-table {
    width: 40px;
    height: 40px;
    min-width: 40px;
}

.mercado-image-card-table-nickname {
    width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    left: 5px;
}

.player-market-raise {
    display: inline-flex;
    align-items: center;
}

.player-market-raise__previuos-value {
    margin-right: 10px;
}

.player-market-raise__current-value {
    margin-right: 10px;
}

.player-market-raise__data-container {
    margin-left: 15px;
}

.player-market-card_name {
    font-size: 18px;
    font-weight: bold;
}

.mercado__index-position {
    font-size: 12px;
}

.subidas-bajadas-nombre {
    font-size: 18px;
}

.difference-to-display-green {
    color: #1a892d;
}

.difference-to-display-red {
    color: #be1212;
}

.mercado-container {
    margin-bottom: 25px;
    font-size: 14px;
}

.mercado-container-options-select {
    width: 180px;
    text-align: left;

    h4 {
        font-weight: normal;
        font-size: 16px;
    }
}

.selecciona-la-jornada {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
}

.top-subidas-bajadas-header {
    text-align: left;
    margin-bottom: -30px;
    z-index: 99999;
    position: relative;
    font-weight: bold;
    font-size: 24px;
}

.team-card-table-container {
    min-height: 30px;
    display: flex;
    justify-content: center;
}

.team-card-table-nickname {
    position: relative;
    top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    white-space: nowrap;
}

.mercado-container__header-container {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.mercado-container__header-container__header {
    width: fit-content;
    margin-bottom: -12px;
    margin-top: 7px;

    @media screen and (min-width: 768px) {
        margin-bottom: -8px;
        margin-top: 12px;
    }
}

.top-subidas-bajadas-container {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 50px;

    div {
        width: fit-content;
        background: #0172aa;
        color: #fff;
        padding: 9px 25px;
        border-radius: 4px;

        &:hover {
            opacity: 0.7;
            cursor: pointer;
        }
    }

    div.active {
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        pointer-events: none;
    }
}

.top-subidas-icon,
.top-bajadas-icon {
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.last-update-text {
    font-size: 16px;
    text-align: right;
    font-weight: 400;
    margin-top: 25px;
}

.top-subidas-bajadas-text {
    text-align: left;
    margin-bottom: 15px;
    font-size: 18px;
}

.top-subidas-bajadas-text-container {
    display: inline-flex;
    margin-bottom: 10px;
    margin-top: 60px;
    float: left;
}

.search-table-container-mb-25 {
    margin-bottom: 25px !important;
}

.search-table-container {
    margin-bottom: 0px;
    display: block;
    text-align: left;

    .search-table-container__label {
        margin-bottom: 6px;
    }

    .search-table-container__input {
        height: 35px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        font-size: 16px;
        padding-bottom: 5px;
        align-items: center;
        padding-left: 8px;
        // color: rgba(0, 0, 0, 0.7);
        padding-top: 4px;
        width: 100%;

        &:focus {
            border: 2px solid #0172aa;
            outline: none;
        }
    }
}

.khKjDK {
    padding-top: 10px;
    padding-bottom: 10px;
}

.mercado-position-container {
    width: 100%;
}

.mercado__player-name-image-container {
    display: inline-grid;
    width: 95px;
}

.mercado__image-card-table-nickname {
    top: -7px;
    margin-left: 5px;
}

.jugadores-position-container-super {
    margin: auto;
    margin-right: 7px;
}

.mercado__player-name-image-container,
.mercado__subida-bajada {
    margin: auto;
}

.estadisticas-globales-filters {
    .journey-container-selector,
    .css-2b097c-container,
    .css-yk16xz-control {
        width: 100%;
    }

    .search-table-container__input,
    .mercado-container-options-select,
    .search-table-container .journey-container-selector .css-2b097c-container,
    .search-table-container .journey-container-selector .css-2b097c-container .css-yk16xz-control {
        width: 100%;
    }
}

@media screen and (min-width: 961px) {
    .mercado-container,
    .rachas-container {
        min-height: calc(100vh - 206px);
    }

    .search-table-container-filter-container {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 600px) {
    .mercado-container__header-container {
        display: block;
    }

    .search-table-container__input,
    .mercado-container-options-select,
    .search-table-container .journey-container-selector .css-2b097c-container,
    .search-table-container .journey-container-selector .css-2b097c-container .css-yk16xz-control {
        width: 100%;
    }

    .mercado__image-card-table-nickname {
        top: -7px;
        margin-left: 5px;
        font-size: 13px;
    }
}

.mercado-datatable {
    border-radius: 4px;
}

#column-mvod,
#column-dp,
#column-n,
#column-df,
#column-teamName,
#column-position,
#column-cmvd,
#column-pmvd,
#column-uvod {
    text-align: center;
}

@media screen and (min-width: 600px) {
    .paypal-promotion {
        width: 100%;
    }
}

@media screen and (max-width: 920px) {
    .search-table-container-filter {
        margin-bottom: 15px;
    }
}

.mercado-fantasy-table-column {
    text-align: center !important;
}

.mercado-fantasy-player-nickname {
    font-size: 12px;
    font-weight: 400;
}

.market-filters-column {
    @media screen and (max-width: $breakpoint-md) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.trend-to-display {
    &__green {
        color: #1a892d;
    }

    &__red {
        color: #be1212;
    }
}

.mercado-datagrid {
    .MuiDataGrid-virtualScroller {
        @media screen and (min-width: 1300px) {
            overflow: hidden !important;
        }
    }

    td {
        padding: 8px 4px !important;
        font-size: 14px;

        @media screen and (max-width: $breakpoint-sm) {
            padding: 8px 4px !important;
        }

        &:first-child {
            padding: 0px !important;
        }
    }

    .player-full__name {
        font-size: 14px;
    }
}

.truncate-125px {
    .image-box__text {
        width: 125px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.player-full {
    display: flex;

    &__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__name {
        margin: 0 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 90px;
        text-align: left;
        font-size: 16px;
    }

    &__name--clickable {
        cursor: pointer;
        color: #0172aa;
    }

    &__right-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;

        .apercibidos-status-icon {
            position: absolute;
            left: 25px;
            top: 2px;
        }
    }
}

.rdt_TableCell div,
.rdt_TableCol div {
    .player-full__left,
    .player-full__middle,
    .player-full__right {
        margin: 0 !important;
    }
}

.rdt_TableCell div,
.rdt_TableCol div {
    .player-full__middle {
        margin: 0 4px !important;
    }
}

.mode-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.mercado-container {
    .mode-radio-group {
        display: flex;
        align-items: center;
    }

    .mode-label {
        font-size: 16px;
        font-weight: bold;
        transition: opacity 0.3s;
    }

    .mode-label.inactive {
        opacity: 0.5;
    }
}
