.noticias-container {
    min-height: calc(100vh - 180px);

    .articulo-teams-related-section {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 8px;
        border-bottom: 1px solid $border-color;
        padding-bottom: 16px;

        .articulo-teams-related {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            &__tag {
                width: 80px;

                a {
                    text-decoration: none !important;
                }
            }

            &__tag-card {
                display: flex;
                align-items: center;
                flex-direction: column;
            }

            &__tag-card-image {
                width: 55px;
            }

            &__tag-card-name {
                text-align: center;
                font-size: 16px;
                text-decoration: none;
            }

            &__tag-title {
                margin: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        .articulo-teams-related-header {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 16px;
        }
    }

    .articulo-social-networks {
        margin-top: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 16px;
    }

    a {
        color: #0d6efd;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    .articulo-content-container {
        p {
            overflow: auto;
        }

        img {
            height: 100%;

            @media screen and (max-width: $breakpoint-sm) {
                height: 100%;
            }
        }

        blockquote {
            font-style: italic;
            color: #333;
            border-left: 4px solid #59a399;
            background: #f9f9f9;
            margin: 20px 0;
            padding: 20px;
            box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
        }
    }

    .articulo-content-container-top {
        margin-bottom: 15px;
        padding-top: 5px;

        .articulo-content-container-top-h1 {
            font-size: 20px;
            line-height: 28px;
            color: rgb(21, 21, 21);
            margin-top: 0px;
        }

        .articulo-content-container-top-h2 {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: rgb(51, 51, 51);
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: justify;
        }
    }
}

.articulo-content-container__image {
    margin-top: 16px;
}

.article-image-sub-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .article-author-data {
        display: flex;
        align-items: center;

        .article-logo-image-profile {
            border-radius: 50%;
        }

        .author-customdate-container {
            margin-left: 8px;

            .author-p {
                margin-bottom: 0;
                margin-top: 12px;
                text-transform: uppercase;
            }
        }
    }
}

.share-line-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .share-line {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__share-title {
        margin-bottom: 0;
    }
}

.share-twitter-icon {
    color: #1d9bf0;
}

.share-facebook-icon {
    color: #0c88ef;
}

.share-whatsapp-icon {
    color: #25d366;
}

.article-social-network-icon {
    margin-right: 10px;
    font-size: 27px;
    margin-top: 16px;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

.articulo-content-container__content-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

    img {
        width: 100% !important;
        height: 100% !important;
    }
}

.tags-article-container {
    margin-top: 8px;
    margin-bottom: 16px;
    min-height: 120px;

    .tags-article-header {
        float: left;
        margin-right: 8px;
        text-decoration: underline;
        position: relative;
        top: 5px;
    }

    .tags-article {
        margin-right: 8px;
        font-weight: 400;
        float: left;
        margin-bottom: 8px;
        padding: 5px 10px;
        background: rgb(241, 241, 241);
        border-radius: 4px;
    }
}

.feed-articles-image {
    width: 100%;
    height: 440px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }

    @media screen and (max-width: 578px) {
        height: 100%;
    }
}

.feed-articles-image-small {
    width: 100%;
    height: 230px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }
}

.post-content {
    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 24px;
        font-weight: 600;
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }
}

.follow-author {
    &__promo {
        padding: 0;
        margin: 10px 0;
    }
}

.noticias-flash-admin {
    &__table {
        width: 100%;

        td {
            :first-of-type {
                width: 50px;
            }
        }
    }
}

.button-div {
    display: flex;
    justify-content: space-around;
    margin-top: 36px;

    .button-yes {
        width: 96px;
        height: 36px;
        border-radius: 4px;
        background-color: #ff215c;
        border: none;
        color: #f2f2f2;
    }

    .button-cancel {
        width: 96px;
        height: 36px;
        border-radius: 4px;
        background-color: #c1c1c1;
        border: none;
        color: #f2f2f2;
    }
}

.div-container {
    min-height: calc(100vh - 100px);
    margin-top: 20px;
    overflow-x: auto;

    .listar-articulos-header {
        padding-left: 0;

        .listar-articulos-header-text {
            font-size: 24px;
            text-decoration: underline;
            margin-bottom: 16px;
        }
    }

    .container-btn-search-status {
        display: flex;
        align-items: center;
        padding-left: 0;

        .MuiInputBase-root {
            height: 35px;
        }

        .MuiFormControl-root {
            margin-left: 0;
        }

        .btn-search-status {
            height: 35px;
            border-radius: 3px;
            text-transform: capitalize;

            a {
                text-decoration: none;
            }
        }

        .link-search-status {
            color: #2271b1;
            border: 1px solid #2271b1;
            text-decoration: none;
            background: #f6f7f7;
        }
    }

    .container-btn-create-article {
        display: flex;
        justify-content: right;
        padding-right: 0;
        align-items: center;

        @media screen and (max-width: 768px) {
            display: block;
            padding-left: 0;
        }

        .btn-create-article {
            background-color: #4681f4;
            border: none;
            width: 125px;
            height: 35px;

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .link-create-article {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.header-row-cta {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.table-style {
    border: 1px solid rgba(0, 0, 0, 0.1);

    .table-body-col {
        line-height: 22px;
    }
}

#icon-modal {
    color: #ff215c;
    border: 2px solid #ff215c;
    border-radius: 50%;
    font-size: 50px;
    margin-left: 141px;
    margin-bottom: 16px;
}

#modal-modal-title {
    font-size: 20px;
    text-align: center;
    color: #5f5c5c;
    margin-bottom: 16px;
}

.text-modal {
    font-size: 12px;
    text-align: center;
    color: #91827c;
}

.button-div {
    display: flex;
    justify-content: space-around;
    margin-top: 36px;

    .button-yes {
        width: 96px;
        height: 36px;
        border-radius: 4px;
        background-color: #ff215c;
        border: none;
        color: #f2f2f2;
    }

    .button-cancel {
        width: 96px;
        height: 36px;
        border-radius: 4px;
        background-color: #c1c1c1;
        border: none;
        color: #f2f2f2;
    }
}

.pagination {
    justify-content: end;
    margin-bottom: 16px;
}

.table-articles-container {
    overflow-x: auto;
}

.flash-list-max-height {
    max-height: 870px;
    overflow: auto;
}

.noticia-flash__player-profile {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .master-player-card {
        .MuiDialogContent-root {
            padding: 0;
            border-top: 0;
            border-bottom: 0;
        }
    }
}

.noticia-page-component {
    padding-left: 0px !important;
    padding-right: 0px !important;

    @media screen and (max-width: $breakpoint-sm) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.carousel-noticias {
    .image-gallery-icon {
        svg {
            width: 40px;

            @media screen and (max-width: $breakpoint-sm) {
                width: 20px;
            }
        }
    }

    .image-gallery-slide img {
        height: 230px;
        object-fit: cover;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .carousel-description {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        padding: 10px 0;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-size: 24px;
    }

    .image-gallery-slide .image-gallery-description {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-size: 20px;
        bottom: 0;
        text-align: left;

        @media screen and (max-width: $breakpoint-sm) {
            font-size: 16px;
        }
    }
}

.carousel-news {
    .image-gallery-slide img {
        height: 600px;

        @media screen and (max-width: $breakpoint-sm) {
            height: 230px;
        }
    }

    .image-gallery-icon {
        svg {
            width: 40px;
            height: 40px;
            background-color: #fff;
            color: #212121;

            @media screen and (max-width: $breakpoint-sm) {
                width: 30px;
                height: 30px;
            }
        }
    }

    .image-gallery-slide .image-gallery-description {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 44px;
        font-weight: 400;
        text-align: center;
        padding: 80px;

        @media screen and (max-width: $breakpoint-sm) {
            font-size: 20px;
        }
    }
}
