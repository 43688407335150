.home-activities {
    margin-bottom: 24px;

    &__title {
        font-size: 20px !important;
        font-weight: 700 !important;
        width: 100%;
    }

    &__date {
        margin-top: 8px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #f5f7f9;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__activity-item {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        text-overflow: ellipsis;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        align-items: flex-start;
        padding: 5px;
    }

    &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }

        .MuiListItemIcon-root {
            min-width: 25px !important;
        }
    }

    &__title-text {
        .MuiTypography-root {
            font-size: 14px;
        }
    }

    &__activity-content {
        width: 100%;
        .MuiListItem-root {
            padding-left: 26px !important;
        }
    }

    &__icon {
        margin-right: 4px;
    }

    &__link {
        font-size: 14px;
        color: $color-clickable-link;
    }
}
