.profile-general-container {
    padding-top: 25px;

    .MuiFormControl-root {
        margin-left: 0;
    }

    .profile-container {
        box-shadow: $box-shadow-main;
        border-radius: 4px;
        margin-bottom: 25px;

        &__information {
            display: flex;
            flex-direction: column;
            margin: 0;

            &__header {
                padding: 5px 0;

                &__title {
                    font-size: 20px;
                    padding: 0px;
                    color: $color-grey-600;
                    font-weight: 400;
                    margin: 0;
                }
            }

            &__email {
                margin-bottom: 24px;
                margin-top: 24px;

                max-width: 100%;

                &__text {
                    margin-bottom: 16px;
                }
            }

            &__nickname {
                margin-bottom: 5px;
                max-width: 100%;

                &__text {
                    margin-bottom: 16px;
                }
            }
        }

        &__save {
            display: flex;
            justify-content: flex-end;
            padding-top: 5px;
            padding-bottom: 5px;

            @media (max-width: 768px) {
                .MuiButtonBase-root {
                    width: 100%;
                }
            }

            &__icon {
                margin-right: 8px;
            }
        }
    }
}