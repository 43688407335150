div.MuiPaper-root.MuiDrawer-paperAnchorRight,
div.MuiPaper-root.MuiDrawer-paperAnchorLeft {
    top: $top-menu-height !important;

    // @media screen and (max-width: $breakpoint-sm) {
    //     top: $navigation-height !important;
    // }
}

.seleccion-drawer {
    div.MuiPaper-root.MuiDrawer-paperAnchorRight {
        top: $top-menu-height !important;
        height: calc(100vh - $top-menu-height);
        overflow-y: auto;

        // @media screen and (max-width: $breakpoint-sm) {
        //     top: $navigation-height !important;
        //     height: calc(100vh - $navigation-height);
        // }
    }
}

.right-drawer-component-content,
.left-drawer-component-content {
    top: $top-menu-height;
    height: calc(100vh - $top-menu-height);

    // @media screen and (max-width: $breakpoint-sm) {
    //     top: $navigation-height !important;
    //     height: calc(100vh - $navigation-height);
    // }

    &__separator {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-top: 15px;
        margin-bottom: 25px;
        width: 100%;
        height: 1px;
    }

    &__user-nickname {
        text-align: center;
        color: rgb(255, 255, 255);
        font-size: 14px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin: 0px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }
}

.left-drawer-component-content {
    overflow-y: auto;
}

.search-players-menu {
    margin: 12px;
}

.left-drawer-component {
    height: 100%;
    width: 450px;

    @media screen and (max-width: 450px) {
        width: 100vw;
    }

    &__profile {
        margin-top: 16px;
        margin-bottom: 48px;
    }

    .left-drawer-component-content {
        background-color: rgb(34, 39, 47);
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        .new-sections-mobile {
            border: none;
            border-radius: 30px;
            padding: 1px 6px;
            background-color: #656af1;
            color: $color-white;
            position: absolute;
            right: 45px;
            font-size: 10px;
        }

        .nav-item-row-parent {
            position: relative;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
        }

        .nav-item-row {
            height: 43px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 8px;
        }

        .nav-item-row-sub {
            padding: 0 24px;
            justify-content: flex-start;
            gap: 16px;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            &:last-child {
                border-bottom: none;
            }
        }

        &__items {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            &__item {
                display: flex;
                justify-content: center;
                flex-direction: column;
                border-bottom: $color-navigation-items-border;
                padding: 0;
                align-items: flex-start;
                cursor: pointer;

                &__btn {
                    background: none;
                    border: none;
                    color: #fff;
                    margin: 0;
                    padding: 0;
                    gap: 16px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        &__items-sublist {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: white;
            width: 100%;
            padding: 0;
        }

        &__items-sublist-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
            width: 100%;
            border-bottom: $color-navigation-items-border;

            &:first-of-type {
                border-top: $color-navigation-items-border;
            }

            &__btn {
                background: none;
                border: none;
                color: #fff;
            }
        }
    }
}

.left-drawer-component.isOpen {
    opacity: 1;
    z-index: 9999999;
}

.left-drawer-component.closed {
    z-index: -999;
    display: none;
    width: 100%;
    visibility: hidden;
}

.login-register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 0;

    .register-btn {
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.7);
        width: 250px;
    }

    &__btn {
        width: 90%;
        margin-bottom: 20px !important;
        margin-left: 5% !important;
    }
}

.right-drawer-component {
    height: 100%;
    width: 450px;

    @media screen and (max-width: 450px) {
        width: 100vw;
    }

    .right-drawer-component-content {
        right: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;
        background-color: $color-white;
        gap: 8px;

        &__tab-container {
            margin-bottom: 0;
            background: rgb(34, 39, 47);
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .react-tabs__tab-list {
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                padding-left: 0;
            }

            &__header {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column-reverse;
            }
        }
    }
}

.right-drawer-component.isOpen {
    opacity: 1;
    z-index: 9999;
}

.right-drawer-component.closed {
    z-index: -999;
    display: none;
}

.drawer-right {
    .MuiDrawer-paper {
        overflow-y: hidden !important;
    }
}