.custom-alert-container {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    padding: 4px 8px;
}

.leyenda-alineaciones {
    &__line {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__streak {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        color: #fff;

        h6 {
            font-size: 12px;
        }
    }
}

.fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background: white; /* Asegúrate de que el fondo sea blanco para que se vea bien */
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}
