$primaryFont: 'Roboto Condensed';

html,
body {
    padding: 0;
    margin: 0;
    background-color: $color-white;
    font-family: $primaryFont;
}

* {
    font-family: $primaryFont;
}

.main-website {
    min-height: calc(100vh - 260px);

    @media screen and (max-width: 960px) {
        min-height: calc(100vh - 239px);
    }

    padding-left: 0;
    padding-right: 0;

    @media screen and (max-width: $breakpoint-sm) {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

.text-align-right {
    text-align: right;
}

* {
    box-sizing: border-box;
}

.navigation-link {
    color: #007bff;
    text-decoration: none;
}

.subidas-bajadas-player-position {
    border-radius: 4px;
    color: $color-white;
    font-size: 10px;
    font-style: normal;
    margin-right: 5px;
    margin-bottom: 7px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.jugadores-position-container-super {
    border-radius: 4px;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
}

.position-color-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.position-color-box {
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
}

.position-color-po {
    background: $position-po-color;
}

.position-color-df {
    background: $position-df-color;
}

.position-color-mc {
    background: $position-mc-color;
}

.position-color-dl {
    background: $position-dc-color;
}

.position-color-dt {
    background: $position-dt-color;
}

.position-color-to {
    background: $position-to-color;
}

.football-stats-input {
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 16px;
    padding-bottom: 5px;
    align-items: center;
    padding-left: 8px;
    font-size: 14px;
    padding-top: 4px;
    width: 100%;

    &:focus {
        border: 2px solid #0172aa;
        outline: none;
    }
}

.football-stats-label {
    margin-bottom: 7px;
}

.item-disabled {
    pointer-events: none;
    opacity: 0.4;

    &:hover {
        cursor: not-allowed;
    }
}

.cta-btn {
    background: #0172aa;
    border-radius: 4px;
    color: #fff;
    padding: 5px 15px;
    border: none;
    outline: none !important;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

.f-bold {
    font-weight: bold;
}

figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 10px;
    margin-inline-end: 0;
}

button#rcc-confirm-button,
button#rcc-decline-button {
    display: block !important;
}

table {
    border-radius: 4px;
    border: 1px solid $color-border;

    tr {
        &:hover {
            background: #eaf5fd;
        }
    }
}

h1 {
    font-weight: 700;
    font-size: 24px;
    text-align: left;

    @media screen and (max-width: $breakpoint-sm) {
        font-size: 20px;
    }
}

.armador-container {
    overflow-x: hidden;
}

.color-for-articles-header {
    color: rgb(30, 41, 59);

    &:hover {
        text-decoration: none;
        color: rgb(59, 130, 246);
    }
}

a.color-white-for-articles-header {
    color: #fff;

    &:hover {
        color: #fff !important;
    }
}

.hover-mouse-opacity {
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 960px) {
    .pl-m-0 {
        padding-left: 0 !important;
    }

    .plr-m-15 {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .mb-50-mobile {
        margin-bottom: 50px;
    }
}

@media screen and (min-width: 960x) {
    .plr-d-15 {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .mb-35 {
        margin-bottom: 35px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px !important;
    }
}

// Escudos
.escudo-equipo-athletic-club {
    width: 25px !important;
    height: 28px !important;
    margin-right: 15px;
}

.escudo-equipo-atletico-de-madrid {
    width: 20px !important;
    height: 27px !important;
    position: relative;
    right: -3px;
    margin-right: 20px;
}

.escudo-equipo-cadiz-cf {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-c-a-osasuna {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-d-alaves {
    width: 35px !important;
    height: 35px !important;
    position: relative;
    right: 5px;
    margin-right: 5px;
}

.escudo-equipo-elche-c-f {
    width: 31px !important;
    height: 31px !important;
    position: relative;
    right: 3px;
    margin-right: 5px;
}

.escudo-equipo-fc-barcelona {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-getafe-cf {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-granada-cf {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-levante-ud {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-rc-celta {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-real-betis {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-real-madrid {
    width: 25px !important;
    height: 30px !important;
    margin-right: 15px;
}

.escudo-equipo-real-sociedad {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-r-valladolid-cf {
    width: 35px !important;
    height: 35px !important;
    position: relative;
    right: 5px;
    margin-right: 5px;
}

.escudo-equipo-sd-eibar {
    width: 20px !important;
    height: 25px !important;
    position: relative;
    right: -3px;
    margin-right: 20px;
}

.escudo-equipo-sd-huesca {
    width: 35px !important;
    height: 35px !important;
    position: relative;
    right: 5px;
    margin-right: 5px;
}

.escudo-equipo-sevilla-fc {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.escudo-equipo-valencia-cf {
    width: 20px !important;
    height: 23px !important;
    position: relative;
    right: -2px;
    margin-right: 20px;
}

.escudo-equipo-villarreal-cf {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

a.li-item-table {
    color: #222 !important;
}

a:hover {
    cursor: pointer;
}

.v-hidden {
    visibility: hidden;
}

.disabled-input {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.11);
    border: 1px solid rgba(0, 0, 0, 0.11);
}

.pl-pr-5 {
    padding-right: 5px;
    padding-left: 5px;
}

.pl-pr-0 {
    padding-right: 0px;
    padding-left: 0px;

    @media screen and (max-width: 600px) {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.cta-button {
    background: #0172aa;

    border-radius: 4px;
    color: #fff;
    padding: 5px 15px;
    border: none;

    &:hover {
        opacity: 0.9;
        cursor: pointer;
    }
}

.cta-button-green {
    background: #01aa4d;
}

.youtube-promotion {
    border: 1px solid $color-border;
    padding: 15px;
    border-radius: 4px;
    font-size: 24px;
    margin-left: 0;
    margin-bottom: 25px;

    a {
        color: #0172aa;
        text-decoration: underline;
    }
}

.btn-secondary {
    color: #fff;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    text-align: center;
}

.btn-third {
    color: #fff;
    background-color: $third-color !important;
    border-color: $third-color !important;
    text-align: center;
}

.btn.btn-primary {
    background-color: $primary-color;

    &:hover {
        opacity: 0.9;
    }
}

.btn.btn-update {
    background-color: $secondary-color;
    color: #fff;

    &:hover {
        opacity: 0.9;
    }
}

@media screen and (max-width: 600px) {
    .youtube-promotion {
        margin-top: 25px;
        width: calc(100vw - 30px);
        margin-left: 15px;
    }

    .react-confirm-alert {
        width: calc(100% - 30px) !important;
    }
}

.google-auto-placed {
    margin-top: 25px;
    margin-bottom: 25px;
}

.adsComponents {
    margin-bottom: 25px;
}

input.generic-input {
    border-radius: 4px;
    height: 40px;
    border: 1px solid #d8e0e5;
    padding-left: 10px;
    padding-right: 15px;

    &:focus {
        border: 2px solid $primary-color;
        outline: none;
    }
}

.mt-25 {
    margin-top: 25px;
}

.clickable-element {
    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
}

.games-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.clickable-element-game {
    cursor: pointer;
    border-radius: 4px;

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;
        padding: 0 8px;
    }

    &__left {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__fixtures {
        display: flex;
        align-items: center;
        padding: 8px;
        justify-content: center;
        gap: 8px;
    }

    &__fixture {
        display: flex;
        gap: 8px;
        width: calc(100% - 34px);
    }

    &__status {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        height: 25px;
    }

    &__score {
        width: 68px;
        display: flex;
        align-items: center;
        padding: 8px;
        justify-content: center;
        gap: 8px;
    }
}

button.save-action-btn {
    background: $primary-color 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
    height: 40px;
    padding: 5px 35px;
    border: none;
}

@media screen and (max-width: 768px) {
    .twitch-widget-container.mobile {
        display: block;
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 769px) {
    .twitch-widget-container.mobile {
        display: none;
    }
}

.qc-cmp2-persistent-link {
    visibility: hidden;
}

.generic-ads {
    text-align: center;
    // border: 1px solid $color-border;
    // margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ads-billboard {
    min-height: 250px;
}

.ads-megabanner,
.ads-megabanner-inferior {
    min-height: 100px;
}

.ads-footer {
    position: absolute;
}

.football-stats-selector {
    margin-bottom: 8px;

    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
    }

    .football-stats-selector__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        margin-bottom: 8px;
        text-align: left;
        font-weight: normal;

        .header-icon {
            margin-right: 10px;
        }
    }
}

.center-flex {
    @include center-content-flex;
}

.disabled-btn {
    cursor: not-allowed;
    opacity: 0.7;
    pointer-events: none;
}

.info-icon {
    color: $info-color;
}

.warning-icon {
    color: $remember-color;
}

.error-icon {
    color: $error-color;
}

div.eUeqdG {
    white-space: inherit !important;
    overflow: initial;
    text-overflow: initial;
}

.center-content-column {
    @include center-content-column-flex;
}

.start-content-column-flex {
    @include start-content-column-flex;
}

.center-content-flex {
    @include center-content-flex;
}

.ads-container {
    width: 100px;
    min-height: 50px !important;
    text-align: center;
    padding: 5px;
    margin: 5px 0;
    min-width: 100%;

    @media screen and (min-width: 769px) {
        min-height: 280px !important;
    }
}

.fixed-ads-aside-pc {
    position: fixed;
    top: 150px;
    min-height: 280px;

    @media screen and (max-width: 1400px) {
        display: none;
    }
}

.fixed-ads-aside-pc.left-aside {
    left: 0;
}

.fixed-ads-aside-pc.right-aside {
    right: 0;
}

.clickable-text-link {
    color: $color-clickable-link;
}

.black-diff {
    color: #000;
}

.red-diff {
    color: $market-red-color;
}

.green-diff {
    color: $market-green-color;
}

.MuiModal-root {
    z-index: 999999 !important;
}

.clickable-icon {
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.css-26l3qy-menu {
    z-index: 9999;
}

.score-missing {
    background: none;
}

.score-none {
    background: #ccc;
}

.score-very-low {
    background: #c25353;
}

.score-low {
    background: #21682d;
}

.score-high {
    background: #4ca55b;
}

.score-very-high {
    background: #1d6fb1;
}

.navigation-link {
    color: #007bff;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
    z-index: 999999999999;
}

.MuiBox-root.css-1wnsr1i {
    border: none;

    &:focus {
        border: none;
    }
}

hr.css-9mgopn-MuiDivider-root {
    .rdt_TableCol {
        div {
            white-space: break-spaces;
            text-overflow: initial;
            overflow: initial;
        }
    }
}

.mercado-fantasy-sticky-bottom {
    position: fixed;
    z-index: 9999;
    display: flex;
    justify-content: center;
    height: 50px;
    width: 100%;
    bottom: 0;
}

.sticky-bottom-ads-component {
    position: fixed;
    z-index: 9999999;
    height: 60px;
    width: 94%;
    margin-left: 3%;
}

.css-3w2yfm-ValueContainer {
    gap: 8px;
}

.text-capitalize {
    text-transform: capitalize;
}

.hbotQl {
    overflow: initial !important;
    white-space: initial !important;
    text-overflow: initial !important;
    width: 80px !important;
}

.publicidad {
    font-size: 10px;
    position: absolute;
    right: 2px;
}

.no-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.site-content-section {
    height: calc(100vh - 100px);
    width: 100%;
}

.site-content-section-no-ads {
    width: 100%;
}

.site-ads-section {
    height: 100px;
    width: 100%;
    position: fixed;
    bottom: 0;
}

#HB_CLOSE_hbagency_space_40734 {
    width: 10px !important;
}

#HB_CLOSE_hbagency_space_40735 {
    width: 10px !important;
}

.rule-stats-table {
    ul {
        margin-left: 0;
        padding-left: 0;

        li {
            font-size: 14px;
            list-style: none;
        }
    }
}

.content-master-container {
    height: calc(100vh - $ads-content-height);
    width: 100%;
}

.ads-master-container {
    height: $ads-content-height;
}

.flashscorees {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #faa501;
    color: #000;
    border-radius: 5px;
    height: 50px;
    margin-bottom: 15px;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    &__text {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 18px;
    }
}

.main-content {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    height: 100%;

    // @media screen and (max-width: $breakpoint-sm) {
    //     margin-bottom: 100px;
    // }
}

// .ads-main-content {
//     height: 100px;
//     position: fixed;
//     bottom: 0;
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: flex-end;
//     // background-color: #2b2f39;

//     @media screen and (min-width: $breakpoint-sm) {
//         display: none;
//     }
// }

.sticky-bottom-menu {
    background-color: #2b2f39;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 100px;

    @media screen and (min-width: $breakpoint-sm) {
        display: none;
    }
}

.ads-content {
    height: $ads-content-height;
    position: fixed;
    bottom: 0;
    width: 100%;

    @media screen and (min-width: $breakpoint-sm) {
        height: 0;
    }
}

.v-hidden-sideads {
    @media screen and (max-width: 1800px) {
        display: none !important;
    }
}

.google-revocation-link-placeholder {
    display: none !important;
}

#div-gpt-ad-1407836289010-0 {
    text-align: center;
}

.display-only-mobile {
    @media screen and (min-width: $breakpoint-sm) {
        display: none !important;
    }
}

.display-only-desktop {
    @media screen and (max-width: $breakpoint-sm) {
        display: none !important;
    }
}

.container {
    @media screen and (max-width: $breakpoint-sm) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.white-space-no-wrap,
.wsnw {
    white-space: nowrap;
}

.gtag-block {
    display: flex;
    align-items: center;
    justify-content: center;

    ins {
        display: block;
    }
}

.MuiTab-root {
    margin: 0;
    padding: 0;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-right: 10px;
}

#search-players-menu {
    .select input {
        font-family: 'curisve';
    }
}

.MuiDrawer-root.MuiDrawer-modal.MuiModal-root {
    top: $top-menu-height;

    .MuiBackdrop-root {
        top: $top-menu-height;

        // @media screen and (max-width: $breakpoint-sm) {
        //     top: $navigation-height;
        // }
    }
}

.twitch-live {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    margin-left: 3px;
}

.twitch-live__title {
    color: #a970ff;
    font-size: 14px;
    margin-bottom: 0;
    opacity: 0;

    /* Inicialmente, oculta el texto */
    animation: twitchLiveAnimation 2s ease infinite;

    /* Aplica la animación con duración de 2 segundos y repetición infinita */
}

@keyframes twitchLiveAnimation {
    0%,
    100% {
        opacity: 0;
        /* Inicio y fin de la animación: texto invisible */
    }

    50% {
        opacity: 1;
        /* Punto medio de la animación: texto visible */
    }
}

.football-stats-selector {
    [class$='-menu'] {
        z-index: 99;
    }
}

.light-menu {
    background-color: #f5f7f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    margin-bottom: 15px;
}

.qc-cmp2-publisher-logo-container figure {
    overflow: hidden !important;
}

.qc-cmp2-summary-buttons button.primary {
    font-size: 14px;
}

.active-league-mode {
    font-weight: 600;
}

.inactive-league-mode {
    opacity: 0.8;
}
