.forma-actual-jugadores {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
}

.forma-actual-jugadores__jornada {
    width: 27px;
    height: 27px;
    text-align: center;
    font-size: 12px;
    background-color: red;
    border-radius: 4px;
    color: #fff;
    margin-right: 5px;
    @include center-content-flex;
}

.rachas-container {
    margin-bottom: 35px;
    width: 960px;
}

.proximo-rival-icon {
    margin-right: 5px;
    font-size: 24px;
}

.proximo-rival-icon-local {
    color: rgba(0, 0, 0, 0.7);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    position: relative;
    top: 6px;
    left: 2.5px;
}

.proximo-rival-icon-visitor {
    position: relative;
    top: 6px;
}

.proximo-rival-icon-visitor-container {
    color: rgba(0, 0, 0, 0.7);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: inline-flex;
}

.proximo-rival-icon-local-container {
    color: rgba(0, 0, 0, 0.7);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: inline-flex;
}

.team-card-table-rachas {
    position: relative;
    margin-left: 5px;
}

.forma-actual-jugadores__jornada-red {
    background-color: $color-jornada-red;
}

.forma-actual-jugadores__jornada-orange {
    background-color: $color-jornada-orange;
}

.forma-actual-jugadores__jornada-dark-green {
    background-color: $color-jornada-dark-green;
}

.forma-actual-jugadores__jornada-green {
    background-color: $color-jornada-green;
}

.forma-actual-jugadores__jornada-dark-blue {
    background-color: $color-jornada-dark-blue;
}

.forma-actual-jugadores__jornada-blue {
    background-color: $color-jornada-blue;
}

.forma-actual-jugadores__jornada-gray {
    background-color: $color-jornada-gray;
    color: #000;
}

.rachas__image-card-table-nickname {
    top: -7px;
}

.rachas-container {
    @media screen and (max-width: 965px) {
        margin-bottom: 35px;
        max-width: 100vw;
    }

    @media screen and (min-width: 961px) {
        min-height: calc(100vh - 216px);
    }
}

.proximo-rival-local-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .localidad {
        display: flex;
        gap: 2px;

        &__icon {
            font-size: 20px;
        }
    }
}

.proximo-rival-local-container,
.proximo-rival-visitor-container {
    display: flex;

    .proximo-rival-team-image-container {
        position: relative;
        top: 7px;
        margin-left: 5px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

.mejores-rachas-page {
    .rdt_TableRow {
        height: 90px;
    }
}
