.fixture-predictions-container {
    width: 100%;

    .file-head {
        background-color: #f6f7f9;
    }

    .text {
        font-weight: 500;
        white-space: nowrap;
    }

    &__table {
        width: 100%;

        td {
            font-size: 13px;
        }

        .text-left {
            text-align: left;
        }

        .text-right {
            text-align: right;
        }

        .text-center {
            text-align: center;
        }
    }

    &__games {
        table {
            tr {
                .text-left {
                    text-align: left;
                    width: 45%;
                }

                .text-center {
                    text-align: center;
                    width: 10%;
                }

                .text-right {
                    text-align: right;
                    width: 45%;
                }
            }
        }
    }
}

.home-icon-fixture {
    font-size: 15px;
    position: relative;
    bottom: 1.7px;
    color: #0172aa;
}

.away-icon-fixture {
    font-size: 15px;
    position: relative;
    bottom: 1.7px;
    color: rgb(19, 92, 49);
}

.fixtures-calendar-container {
    height: 1676px;
    overflow-y: scroll;
    overflow-x: hidden;

    .game-list-header {
        font-size: 16px;
        box-shadow: $box-shadow-main;
        margin-bottom: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .game-list-header-left {
        margin-right: 5px;
        margin-bottom: 10px;
        border-radius: 4px;
    }

    .game-list-header-left + .fixtures-box-list {
        margin-right: 5px;
        margin-left: 0px;
    }

    .game-list-header-right {
        margin-left: 5px;
        margin-bottom: 10px;
        border-radius: 4px;
    }

    .game-list-header-right + .fixtures-box-list {
        margin-left: 5px;
        margin-right: 0;
    }

    .fixture-box {
        display: flex;
        box-shadow: $box-shadow-main;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 10px;
        border-radius: 4px;
        color: #000000;
        min-height: 152px;

        .fixture-box-competition-container {
            display: flex;
            align-items: center;
        }

        .fixture-box-date {
            display: flex;
            justify-content: center;
            font-size: 14px;
            text-transform: capitalize;
        }

        .fixture-box-teams {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .fixture-box-teams-shield {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .fixture-box-left-team,
                .fixture-box-right-team {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                .fixture-box-team-name {
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    text-overflow: ellipsis;

                    @media screen and (max-width: $breakpoint-md) {
                        max-width: 120px;
                    }

                    @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
                        max-width: 180px;
                    }

                    @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl) {
                        max-width: 120px;
                    }
                }
            }

            .fixture-box-separator {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .fixture-box-separator-top {
                    font-size: 12px;
                }

                .fixture-box-separator-bottom {
                    font-size: 14px;
                }
            }

            .fixture-goals-box-separator {
                margin-left: 7px;
                margin-right: 7px;
                color: #000000;
            }

            .fixture-goals-box {
                color: #000000;
                border: 1px solid hsl(265, 19%, 88%);
                border-radius: 3px;
                font-size: 14px;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .fixture-box-status {
            display: flex;
            justify-content: center;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
}

.master-player-modal .fixtures-calendar-container {
    height: auto;
}

.game-green {
    background-color: rgba(224, 241, 224, 0.815);
}

.game-red {
    background-color: #e4a7a7a2;
}

.game-yellow {
    background-color: #eeee8596;
}

.game-list-header-text-caption {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
    font-weight: normal;
}

.fixtures-card-container-drawer.fixtures-card-container {
    .game-row {
        border-top: 1px solid $color-border;
        max-height: calc(100vh - 216px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.fixtures-card-container {
    border-radius: 4px;

    .fixtures-line {
        border-bottom: 1px solid $color-border !important;
        padding: 10px;
    }

    .fixtures-card-new {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__date {
            .game-time {
                display: flex;
                justify-content: center;
                font-size: 13px;
                color: rgba(33, 37, 41, 0.7);
                margin-bottom: 0;
                margin-bottom: 5px;
                text-transform: capitalize;
            }
        }

        &__teams {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__away {
                &__image {
                    margin-right: 5px;
                }
            }

            &__local {
                &__name {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                &__text {
                    font-size: 14px;

                    @media screen and (min-width: $breakpoint-md) {
                        font-size: 16px;
                    }
                }
            }

            &__score {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .game-score {
                    &__number {
                        border: 1px solid hsl(265, 19%, 88%);
                        padding: 3px;
                        border-radius: 3px;
                        text-align: center;
                        font-size: 14px;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .matches-fantasy {
                    color: $color-white;
                }
            }
        }

        &__status {
            display: flex;
            justify-content: center;
            font-size: 13px;
            color: rgb(33, 37, 41);
            position: relative;
            align-items: center;

            .matches-fantasy-text {
                text-align: center;
            }
        }
    }

    .fixtures-card-new--left {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .col-6-left {
        border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    .game-row {
        padding-bottom: 0;
    }
}

.fixtures-card-selector {
    margin-bottom: 25px;

    &__header {
        margin-bottom: 8px;
    }
}

.live-fixture-league-name {
    background: #f5f7f9;
    height: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
}

.live-fixtures-header {
    background-color: $color-header-section;
    color: $color-white;
    border-radius: 4px;
    padding-left: 8px;
    height: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;

    h2 {
        font-size: 16px;
        margin-bottom: 0;
    }
}

.fixture-comparison-component {
    border: 1px solid $color-border;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;

    .fixture-comparison-component-header {
        border-bottom: 1px solid $color-border;
        padding: 5px 5px 10px 5px;
    }
}

.fixture-prediction-winner-container {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-border;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 4px;
    margin-bottom: 15px;

    .fixture-prediction-winner-container-text {
        border-bottom: 1px solid $color-border;
        padding: 5px 5px 10px 5px;
    }

    .fixture-prediction-winner-container__box {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        margin-top: 10px;

        .fixture-prediction-winner-container__box-home-container {
            background: #1d6fb1;
            height: 10px;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }

        .fixture-prediction-winner-container__box-draw-container {
            background: rgba(204, 204, 204, 0.3);
            height: 10px;
        }

        .fixture-prediction-winner-container__box-away-container {
            background: #1a892d;
            height: 10px;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .fixture-prediction-winner-container__legend {
        display: flex;
        justify-content: space-between;

        .fixture-prediction-winner-container__legend-text {
            font-size: 12px;
            color: #000;
            margin-left: 5px;
        }
    }
}

.live-fixture-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: $box-shadow-main;
    padding: 10px;

    &__before {
        opacity: 0.7;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    &__after {
        opacity: 0.5;
        transform: rotate(180deg);
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    &__select {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    &__box {
        display: flex;
    }
}

.football-events {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 10px;
    overflow-x: auto;
    position: relative;
    box-shadow: $box-shadow-main;

    &__bar {
        display: flex;
        width: auto;
        height: 240px;
        border-radius: 10px;
        position: relative;
        align-items: center;

        &-inner {
            display: flex;
            margin-right: -25px;
        }
    }

    &__bar-line {
        position: absolute;
        top: 120px;
        width: 0;
        height: 1px;
        background-color: #e4e4e4;
        z-index: 1;
    }

    &__event-bubble {
        background-color: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 12px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        margin-right: 60px;
        z-index: 2;

        &__event-component {
            position: absolute;
            left: -25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            h6 {
                font-size: 12px;
                margin-bottom: 0;

                width: 80px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: center;
            }
        }

        &__event-component-0 {
            h6 {
                padding-left: 10px;
                white-space: nowrap;
            }
        }
    }

    &__event-bubble-last {
        margin-right: 0;
    }
}
