.mercado-fichajes {
    &__operation {
        box-shadow: $box-shadow-main;
        width: 100%;
        margin-bottom: 16px;
        display: flex;
    }

    &__entity {
        width: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px 0;
        gap: 4px;
    }

    &__entity-text {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        background-color: cadetblue;
        color: #fff;
        width: 50px;
        text-align: center;
        border-radius: 4px;
    }
}