.satisfaction-survey {
    @media screen and (min-width: 600px) {
        width: 500px;
    }

    @media screen and (max-width: 600px) {
        width: 95%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &__title {
            position: relative;
            top: 12px;
            text-align: center;
            margin-bottom: 0;
        }

        &__text {
            position: relative;
            top: 12px;
            text-align: center;
            font-size: 12px;
            font-style: italic;
            margin-bottom: 25px;
        }
    }

    &__container {
        padding: 5px;
        display: flex;
        margin-bottom: 12px;
        flex-direction: column;
        justify-content: center;

        &__textarea {
            border: 1px solid $color-grey-100;
            height: 105px;
            text-align: justify;
            padding: 5px;
            border-radius: 5px;
            margin-top: 10px;
        }
    }

    &__points {
        padding: 5px;
        border-radius: 5px;

        &__label {
            margin-bottom: 16px;
        }

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 32px;
            padding: 5px;

            :hover {
                cursor: pointer;
            }

            @media screen and (max-width: 350px) {
                gap: 3px;
            }

            &__rating {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid $color-grey-100;
                border-radius: 4px;
                width: 70px;
                height: 50px;

                &__icon-satisfied {
                    color: #84ce5e;
                    font-size: 32px;
                }

                &__icon-very-satisfied {
                    color: #73c649;
                    font-size: 32px;
                }

                &__icon-moderate-satisfied {
                    color: #ffdd92;
                    font-size: 32px;
                }

                &__icon-dissatisfied {
                    color: #ea4e09;
                    font-size: 32px;
                }

                &__icon-very-dissatisfied {
                    color: #f12020;
                    font-size: 32px;
                }
            }
        }
    }

    &__submit {
        text-align: center;
        margin-top: 24px;
        margin-bottom: 16px;
    }
}

.pintado {
    background-color: rgb(217, 231, 235);
}
