.premios-y-sorteos {
    p,
    h2,
    h3 {
        margin-bottom: 16px;
    }

    .camiseta-container {
        display: flex;
        flex-direction: column;
        max-width: 350px;

        @media screen and (max-width: $breakpoint-sm) {
            justify-content: center;
            align-items: center;
        }
    }
}

.question-sorteo {
    display: flex;
    max-width: 400px;
    justify-content: space-between;
    margin-bottom: 24px;

    &__team {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 30%;

        p {
            margin: 0;
        }
    }

    &__input {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.24); /* Color del borde negro */
        text-align: center;
        font-size: 20px;
    }

    &__result {
        display: flex;
        width: 40%;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }

    .button-material {
        margin-bottom: 24px;
    }
}

.question-scorer {
    margin-bottom: 16px !important;

    &__select {
        margin-bottom: 16px !important;
    }
}
