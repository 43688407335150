@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-toastify/dist/ReactToastify.css';
// @import 'bootstrap/scss/mixins';
// @import 'bootstrap/scss/functions';
// @import 'bootstrap/scss/variables';
// @import 'bootstrap/scss/variables-dark';
// @import 'bootstrap/scss/maps';
// @import 'bootstrap/scss/utilities';
// @import 'bootstrap/scss/reboot';
// @import 'bootstrap/scss/root';
// @import 'bootstrap/scss/grid';
// @import 'bootstrap/scss/_containers';

@import './shared/variables.scss';
@import './shared/mixins.scss';
@import './shared/common.scss';
@import './shared/site.scss';
@import './shared/red-dot-blinking.scss';
@import './shared/footer.scss';
@import './shared/react-tabs.scss';
@import './shared/price-range.scss';
@import './shared/table-material.scss';
@import './shared/layout.scss';
@import './shared/player-cards.scss';
@import './shared/ads.scss';
@import './shared/custom.scss';

@import './components/partido.scss';
@import './components/layout/desktop.scss';
@import './components/layout/drawer.scss';
@import './components/layout/logo.scss';
@import './components/layout/main-navigation.scss';
@import './components/layout/mobile.scss';
@import './components/layout/responsive-navigation.scss';

@import './components/home.scss';
@import './components/custom-modal.scss';
@import './components/not-found.scss';
@import './components/noticias.scss';
@import './components/feed-component.scss';
@import './components/fixtures.scss';
@import './components/mercado.scss';
@import './components/estadisticas-jugadores-fantasy.scss';
@import './components/estadisticas-fantasy.scss';
@import './components/profile.scss';
@import './components/setting.scss';
@import './components/comparador-fantasy.scss';
@import './components/market-values-player-modal.scss';
@import './components/teams.scss';
@import './components/fantasy-collapsible.scss';
@import './components/players-model.scss';
@import './components/multiple-progress-bar.scss';
@import './components/calendario-fantasy.scss';
@import './components/analisis-plantilla.scss';
@import './components/mejores-rachas-fantasy.scss';
@import './components/standing.scss';
@import './components/mis-plantillas-fantasy.scss';
@import './components/encuesta-de-satisfaccion.scss';
@import './components/contactanos.scss';
@import './components/politicas-de-privacidad.scss';
@import './components/administrador-fantasy.scss';
@import './components/lineup-selector.scss';
@import './components/account.scss';
@import './components/fantasy-manager.scss';
@import './components/predicting-manager.scss';
@import './components/alineaciones-probables.scss';
@import './components/datos.scss';
@import './components/squads.scss';
@import './components/colaboradores.scss';
@import './components/jugadores.scss';
@import './components/puja-fantasy.scss';
@import './components/calendar-competitions.scss';
@import './components/configuration.scss';
@import './components/master-player-modal.scss';
@import './components/convocatorias.scss';
@import './components/drawer-convocatorias.scss';
@import './components/virus-fifa.scss';
@import './components/marketing-afiliados.scss';
@import './components/mercado-fichajes.scss';
@import './components/comparador-de-jugadores.scss';
@import './components/clasificacion-competiciones.scss';
@import './components/biwenger.scss';
@import './components/jugadores-navigation.scss';
@import './components/install-app.scss';
@import './components/pwa-banner.scss';
@import './components/premios-y-sorteos.scss';
@import './components/activities.scss';
@import './components/salon-de-la-fama.scss';
@import './components/equipo.scss';
@import './components/chollos.scss';
@import './components/quedada-fantasy.scss';
@import './components/lineups-bar.scss';