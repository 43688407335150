.predicting-manager {
    margin-top: 20px;
    &__table {
        th {
            font-weight: 700;
        }
    }

    &__table-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        margin-bottom: 16px;
        margin-top: 16px;
    }

    &__column {
        font-size: 16px;
    }
}

.alineaciones-probables {
    #fixture-id-select {
        display: flex;
        width: 100%;

        .fixture-id-select__mid-column {
            width: 10%;
            text-align: center;
        }

        .fixture-id-select__column-first {
            display: flex;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
            width: 45%;
        }

        .fixture-id-select__column-last {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            align-items: center;
            width: 45%;
        }
    }
}

#react-select-managers-id-listbox {
    z-index: 2;
}

.player-information-container {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow-main;
    margin-top: 24px;

    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    &__header {
        padding: 8px;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        cursor: pointer;
        border-bottom: 1px solid $color-border;
    }

    &__items {
        display: flex;
        flex-direction: column;
        padding-left: 8px;
    }

    &__item {
        display: flex;
        border-bottom: 1px solid $color-border;
        padding-bottom: 12px;
        padding-top: 12px;
        position: relative;
    }

    &__item-position {
        width: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 4px;
    }

    &__item-image {
        width: 70px;
    }

    &__item-info {
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__item-info-name {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
    }

    &__item-info-status {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__no-items {
        height: 95px;
        box-shadow: $box-shadow-main;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }

    &__parte-medico {
        position: absolute;
        right: 5px;
        top: 12px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 2px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    &__apercibido {
        position: absolute;
        right: 5px;
        bottom: 12px;
    }
}

.players-lineup {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    box-shadow: $box-shadow-main;

    &__title {
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 600;
    }

    &__header {
        padding: 8px;
        background: #f9f9f9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        cursor: pointer;
        border-bottom: 1px solid $color-border;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        box-shadow: $box-shadow-main;
    }

    &__item {
        display: flex;
        border-bottom: 1px solid $color-border;
        padding-bottom: 12px;
        padding-top: 12px;
        position: relative;
        width: 20%;
        height: 130px;
        align-items: center;
        justify-content: center;
    }

    &__disabled {
        opacity: 0.9;
    }

    &__item-card {
        position: relative;

        h6 {
            width: 70px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__status {
        position: absolute;
        bottom: -2px;
        right: 5px;
    }

    &__streak {
        position: absolute;
        top: -19px;
        left: -9px;
    }

    &__country {
        position: absolute;
        top: 0px;
        left: 3px;
    }

    .image-box__text {
        white-space: nowrap;
        max-width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
