.game-result-container {
    width: 100%;
    margin-bottom: 25px;
    text-align: center;
    border-radius: 4px;
    box-shadow: $box-shadow-main;
    color: #000;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 1px 2px 1px #e1e1e1 !important;

    .game-result-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-border;
        padding-top: 8px;
        padding-bottom: 8px;

        .game-result-info-fixture {
            display: flex;
            justify-content: center;
        }

        .game-result-info-left {
            display: flex;
            align-items: center;
        }

        .game-result-info-right {
            display: flex;
            align-items: center;

            &__text {
                font-size: 14px;
                color: rgba(33, 37, 41, 0.7);
                margin-bottom: 0;
            }
        }
    }

    .game-result-info-referee {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-left: 15px;
        gap: 5px;
    }

    .game-result-info-stadium {
        display: flex;
        padding-top: 10px;
        justify-content: center;
        width: 100%;
        align-items: center;
        padding-left: 15px;
        gap: 5px;
        border-top: 1px solid $color-border;
    }

    .game-result-info-header {
        font-size: 14px;
        margin: 0;
        color: #808692;
    }

    .game-result-info-header-league-name {
        &::after {
            content: '-';
            margin-right: 5px;
            margin-left: 5px;
        }
    }

    .game-result-info-sub-header {
        font-size: 14px;
        margin: 0;
        color: rgba(33, 37, 41, 0.7);
    }

    .game-result-container-header {
        width: 100%;
        margin-bottom: 10px;
        padding-top: 10px;
    }

    .game-result-container-body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;

        .game-result-container__team {
            display: flex;
            align-items: center;
            width: 33%;

            &:first-of-type {
                justify-content: flex-start;
            }

            &:last-of-type {
                justify-content: flex-end;
            }

            .game-result-container__teams-name {
                font-size: 24px;
                position: relative;
                color: rgb(33, 37, 41);
                margin: 0;
            }

            .game-result-container__teams-name-away {
                text-align: right;
            }
        }

        .game-result-container__teams__result {
            display: flex;
            gap: 5px;
            align-items: center;
            width: 33%;
            justify-content: center;

            .game-result-container__teams__result-box {
                border: 1px solid hsl(265, 19%, 88%);
                color: rgb(33, 37, 41);
                font-size: 28px;
                padding: 5px 17px;
                border-radius: 4px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .game-result-container__teams__minus {
                padding-top: 8px;
                font-size: 24px;
            }
        }
    }

    .game-result-container-goals {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 8px;
        margin-bottom: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        &__line {
            display: flex;
            gap: 4px;
            font-size: 14px;
        }
    }

    .game-result-container-footer {
        padding-bottom: 10px;
        gap: 5px;
        display: flex;
        flex-direction: column;
    }
}

.fixture-lineup-container {
    margin-bottom: 25px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    &__stadium-container {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        .stadium__image {
            width: 100%;
        }
    }

    &__lineup-container {
        &__header {
            height: 40px;
            border-left: 6px solid #00acff;
            padding-left: 10px;
            padding-top: 5px;
            font-size: 18px;
            font-weight: bold;

            &__team-name {
                text-transform: uppercase;
            }

            &__team-formation {
                margin-left: 5px;
                font-size: 14px;
                color: #babcc0;
            }
        }

        &__section {
            background: #f5f7f9;
            height: 30px;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
            padding-top: 5px;
            padding-right: 10px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            &__text {
                text-transform: uppercase;
                font-size: 14px;
                padding-left: 16px;
                font-weight: bold;
            }
        }

        &__players {
            height: 30px;
            border-top: 1px solid rgba(0, 0, 0, 0.06);
            display: flex;
            padding-top: 5px;

            &__number {
                margin-right: 30px;
                font-size: 14px;
                color: #babcc0;
                padding-left: 16px;
                width: 25px;
            }

            &__name {
                font-size: 14px;
                margin-right: 5px;
            }

            &__events {
                .custom-subs-out-icon {
                    transform: rotate(90deg);
                }

                .custom-subs-in-icon {
                    transform: rotate(270deg);
                }
            }
        }
    }
}

.fixture-lineup-container-right {
    @media screen and (max-width: 600px) {
        float: none;
    }
}

.head-to-head-container {
    margin-bottom: 25px;

    &__game {
        cursor: pointer;

        &__header {
            text-align: center;
            background-color: #f5f7f9;
            border-radius: 4px;
            height: 50px;
            font-size: 14px;
            color: #babcc0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__body {
            display: flex;
            justify-content: space-between;
            height: 80px;
            background-color: #fff;
            color: #000;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;

            &:hover {
                background: $hover-color-gray;
            }

            &__team {
                display: flex;

                &__image {
                    height: 40px;
                    width: 40px;
                }
            }

            &__score {
                display: flex;

                &__number-left {
                    font-size: 20px;
                    background: rgb(91, 131, 177);
                    padding: 0 10px;
                    border-radius: 4px;
                    color: white;
                    display: flex;
                    align-items: center;
                    height: 33px;
                }

                &__number-right {
                    font-size: 24px;
                    font-size: 20px;
                    background: rgb(91, 131, 177);
                    padding: 0 10px;
                    border-radius: 4px;
                    color: white;
                    display: flex;
                    align-items: center;
                    height: 33px;
                }

                &__number-separator {
                    margin-right: 10px;
                    margin-left: 10px;
                    font-size: 24px;
                }
            }
        }

        &__footer {
            height: 40px;
            display: flex;
            justify-content: center;
            background: rgb(47, 79, 79);
            align-items: center;

            &__league-image {
                border-radius: 4px;
            }

            &__league-name {
                font-size: 16px;
                margin-left: 8px;
                color: $color-white;
                font-weight: normal;
                margin-bottom: 0;
            }
        }
    }
}

.fixture-events-container {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 50px;
    border-radius: 5px;
    margin: 0;

    div {
        &:first-child {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 50px;
    }

    .fixture-events-table {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.06);

        &__header {
            padding-top: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            background: #f5f7f9;

            &__row {
                height: 60px;

                &__home {
                    text-align: left;
                    display: inline-flex;
                    padding-top: 15px;
                    padding-bottom: 20px;
                    padding-left: 10px;

                    &__image {
                        width: 40px;
                        height: 40px;
                    }

                    &__name {
                        max-width: 120px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        line-height: 45px;
                        margin-left: 5px;
                    }
                }

                &__away {
                    padding-top: 15px;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding-right: 10px;

                    &__image {
                        width: 40px;
                        height: 40px;
                        position: relative;
                        margin-left: 6px;
                    }

                    &__name {
                        max-width: 120px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        position: relative;
                    }
                }
            }
        }

        &__body {
            &__row {
                height: 40px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);

                &__home {
                    padding-top: 15px;
                    padding-left: 7px;
                    padding-bottom: 7px;
                    text-align: left;
                    font-size: 12px;
                    width: 40%;

                    &__event-row {
                        display: flex;
                        justify-content: flex-start;
                    }
                }

                &__minute {
                    text-align: center;
                    padding-top: 7px;
                    font-size: 12px;
                    width: 15%;
                    font-weight: bold;
                }

                &__away {
                    font-size: 12px;
                    padding-top: 15px;
                    padding-bottom: 7px;
                    padding-right: 7px;
                    text-align: right;
                    width: 40%;

                    &__event-row {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}

.fixture-events-container__header {
    color: #fff;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    height: 40px;
    background-color: $color-header-section;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &__text {
        font-size: 18px;
        margin-bottom: 0;
    }
}

.custom-event-icon {
    width: 25px;
    height: 25px;
    padding: 2px;
    position: relative;
    bottom: 1px;
}

.removed-yellow-card {
    position: relative;
    width: 14px;
    height: 18px;
    background: #fedd26;
    color: #ac9e9e;
    @include center-content-flex;
    border-radius: 4px;
    margin-right: 4px;
    font-size: 14px;
}

.custom-own-goal-icon {
    color: rgb(155, 22, 22);
}

.custom-yellow-card-icon {
    color: #fedd26;
    transform: rotate(90deg);
}

.custom-red-card-icon {
    color: rgb(156, 23, 23);
    transform: rotate(90deg);
}

.custom-subs-in-icon {
    padding: 2px;
    font-size: 12px;
    color: #00a04f;
}

.custom-subs-out-icon {
    font-size: 12px;
    color: rgb(201, 55, 55);
    transform: rotate(180deg);
}

.no-goal-var {
    color: rgb(255 133 29);
}

.custom-assist-icon {
    color: rgb(30, 113, 180);
}

.fixture-lineup-stadium-container {
    background: url('https://assets.analiticafantasy.com/estadio/estadio.png?width=800');
    height: 700px;
    width: 100%;
    background-size: 100% 100%;
    margin-bottom: 25px;
    border-radius: 4px;

    .fixture-lineup-container__stadium-players {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-top: 12px;
        flex-flow: column-reverse;
        padding: 30px 0px 20px 0px;

        .formation-players-line {
            display: flex;
            justify-content: center;
            position: relative;

            &__streak {
                position: absolute;
                top: 0;
                left: 0px;
            }

            .image-box__text {
                margin: 0;
                font-size: 12px;
                background: #fff;
                width: 70px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-radius: 2px;
                color: #000;
                text-align: center;
                height: 20px;
                padding: 3px 2px 0;
            }

            .tooltip-container {
                position: absolute;
                top: -20px;
                right: -3px;
                display: flex;

                .custom-event-icon {
                    padding: 1px;
                    width: 16px;
                    height: 16px;
                    bottom: 6px;
                }

                .custom-goal-icon {
                    color: #fff;
                }

                .custom-subs-out-icon {
                    transform: rotate(90deg);
                    width: 22px;
                    font-size: 22px;
                    height: 20px;
                }
            }

            &__container {
                position: relative;
                text-align: center;
                margin: 0;

                &__image {
                    border-radius: 4px;
                    -webkit-box-shadow: 3px 0px 26px 0px rgba(0, 170, 141, 1);
                    -moz-box-shadow: 3px 0px 26px 0px rgba(0, 170, 141, 1);
                    box-shadow: 3px 0px 26px 0px rgba(0, 170, 141, 1);
                    margin-bottom: 10px;
                }

                &__name {
                    border-radius: 2px;
                    font-size: 12px;
                    padding: 0px 5px;
                    width: 68px;
                    color: black;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 16px;
                    background: #fff;
                    overflow-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }
            }
        }
    }
}

.no-events {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partido-container {
    min-height: calc(100vh - 200px);

    &__content {
        @media screen and (max-width: $breakpoint-lg) {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}

.fixture-small-full-container {
    display: flex;
    overflow: auto;
    max-width: 100vw;
    margin-bottom: 0;
    padding-bottom: 15px;
}

.fixture-small-card-section-container {
    padding: 0;

    @media (min-width: 600px) {
        margin-bottom: 25px;
    }

    .fixture-small-card-subsection-container {
        padding: 0 15px 0 15px;
        border-radius: 4px;
        width: 100%;
        @include center-content-flex;
    }
}

.fixture-small-card-container {
    margin-right: 8px;

    &:hover {
        cursor: pointer;
    }

    .status-container {
        @include center-content-flex;
        background: $color-navigation-menu;
        color: $color-white;
        font-size: 14px;
        position: relative;
    }

    .game-data {
        display: flex;
        flex-direction: column;
        width: 180px;
        padding: 5px;

        .live-fixture-image {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }

        .home-away-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            .left {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .live-fixture-team {
                font-size: 14px;
                font-weight: normal;
                margin-bottom: 0;
                max-width: 100px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .live-fixture-score {
                background: #5b83b1;
                width: 25px;
                height: 25px;
                color: $color-white;
                border-radius: 4px;
                font-size: 14px;
                margin-bottom: 0;
                @include center-content-flex;
            }
        }
    }

    .game-league {
        @include center-content-flex;
        font-size: 14px;
        background: $menu-sub-section;
        font-size: 12px;
    }
}

.fixture-elapsed-time {
    font-size: 12px;
}

.fixture-box-hover {
    &:hover {
        cursor: pointer;
        opacity: 0.7;
        transform: scale(1.02);
    }
}

.live-match-tabs-container__tabs {
    padding-right: 5px;
    padding-left: 5px;

    .react-tabs__tab-list {
        @include center-content-flex;
        margin-bottom: 15px;

        .react-tabs__tab {
            width: 50%;
            height: 40px;
            @include center-content-flex;
        }

        .react-tabs__tab--selected {
            background: #00a04f;
        }
    }
}

.live-match-container {
    padding-right: 5px;
    padding-left: 5px;
    border: 1px solid $color-border;
    border-radius: 4px;

    &__component {
        @include center-content-flex;
        justify-content: space-between;
        border-bottom: 1px solid $color-border;
        padding-top: 5px;
        padding-bottom: 5px;

        &__left {
            @include start-content-flex;
            width: calc((100% - 80px) / 2);

            &__name {
                font-size: 14px;
            }
        }

        &__center {
            @include center-content-flex;
            width: 120px;
            gap: 8px;

            &__home {
                width: 35px;
                height: 30px;
                color: #fff;
                @include center-content-flex;
                border-radius: 4px;
                margin-right: 5px;
                font-size: 14px;
            }

            &__visitor {
                width: 35px;
                height: 30px;
                color: #fff;
                border-radius: 4px;
                @include center-content-flex;
                font-size: 14px;
            }
        }

        &__right {
            @include end-content-flex;
            width: calc((100% - 80px) / 2);

            &__name {
                font-size: 14px;
            }
        }
    }
}

.live-match-container__component__left {
    .player-data_image-estadisticas-en-vivo {
        border-radius: 50%;
    }

    &__name {
        margin-left: 8px;
    }

    .player-circle-position-1 {
        border: 1px solid $position-po-color !important;
    }

    .player-circle-position-2 {
        border: 1px solid $position-df-color !important;
    }

    .player-circle-position-3 {
        border: 1px solid $position-mc-color !important;
    }

    .player-circle-position-4 {
        border: 1px solid $position-dc-color !important;
    }

    .player-circle-position-5 {
        border: 1px solid $position-dt-color !important;
    }
}

.live-match-container__component__right {
    .player-data_image-estadisticas-en-vivo {
        border-radius: 50%;
    }

    &__name {
        margin-right: 8px;
    }

    .player-circle-position-1 {
        border: 1px solid $position-po-color !important;
    }

    .player-circle-position-2 {
        border: 1px solid $position-df-color !important;
    }

    .player-circle-position-3 {
        border: 1px solid $position-mc-color !important;
    }

    .player-circle-position-4 {
        border: 1px solid $position-dc-color !important;
    }

    .player-circle-position-5 {
        border: 1px solid $position-dt-color !important;
    }
}

.fixture-lineup-container__stadium-players {
    .defense-line-3 {
        gap: 8%;
    }

    .defense-line-4 {
        gap: 8%;
    }

    .defense-line-5 {
        gap: 5%;
    }

    .defense-line-6 {
        gap: 2%;
    }

    .midfield-line-2 {
        gap: 8%;
    }

    .midfield-line-3 {
        gap: 8%;
    }

    .midfield-line-4 {
        gap: 6%;
    }

    .midfield-line-5 {
        gap: 4%;
    }

    .forward-line-2 {
        gap: 6%;
    }

    .forward-line-3 {
        gap: 6%;
    }

    .defense-line-3 .player-lineup-confirmed:nth-child(2) {
        transform: translateY($position-move);
    }

    .defense-line-4 .player-lineup-confirmed:nth-child(1),
    .defense-line-4 .player-lineup-confirmed:nth-child(4) {
        transform: translateY(-$position-move);
    }

    .defense-line-5 .player-lineup-confirmed:nth-child(1),
    .defense-line-5 .player-lineup-confirmed:nth-child(5) {
        transform: translateY(-$position-move);
    }

    .defense-line-6 .player-lineup-confirmed:nth-child(1),
    .defense-line-6 .player-lineup-confirmed:nth-child(6) {
        transform: translateY(-$position-move);
    }

    .defense-line-6 .player-lineup-confirmed:nth-child(3),
    .defense-line-6 .player-lineup-confirmed:nth-child(4) {
        transform: translateY($position-move);
    }

    .midfield-line-3 .player-lineup-confirmed:nth-child(2) {
        transform: translateY(-$position-move);
    }

    .midfield-line-4 .player-lineup-confirmed:nth-child(1),
    .midfield-line-4 .player-lineup-confirmed:nth-child(4) {
        transform: translateY(-$position-move);
    }

    .midfield-line-5 .player-lineup-confirmed:nth-child(1),
    .midfield-line-5 .player-lineup-confirmed:nth-child(5) {
        transform: translateY($position-move);
    }

    .midfield-line-5 .player-lineup-confirmed:nth-child(3) {
        transform: translateY(-$position-move);
    }

    .midfield-line-6 .player-lineup-confirmed:nth-child(1),
    .midfield-line-6 .player-lineup-confirmed:nth-child(6) {
        transform: translateY($position-move);
    }

    .midfield-line-6 .player-lineup-confirmed:nth-child(3),
    .midfield-line-6 .player-lineup-confirmed:nth-child(4) {
        transform: translateY(-$position-move);
    }

    .forward-line-3 .player-lineup-confirmed:nth-child(2) {
        transform: translateY(-$position-move);
    }

    .forward-line-4 .player-lineup-confirmed:nth-child(2),
    .forward-line-4 .player-lineup-confirmed:nth-child(3) {
        transform: translateY(-$position-move);
    }
}
