.setting-container {
    &__change-password {
        box-shadow: $box-shadow-main;
        border-radius: 4px;
        padding-left: 5px;

        &__header {
            padding-top: 8px;
            padding-left: 8px;

            &__text {
                font-size: 20px;
                color: $color-grey-600;
                font-weight: 400;
            }
        }

        &__password {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__save {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 12px;

            @media (max-width: 768px) {
                .MuiButtonBase-root {
                    width: 100%;
                }
            }
        }
    }

    &__delete {
        margin-top: 50px;
        box-shadow: $box-shadow-main;
        border-radius: 4px;
        padding: 5px 0 5px 14px;
        margin-bottom: 25px;

        &__header {
            padding-left: 14px;
            padding: 5px 0;
            margin: 0;

            &__text {
                font-size: 20px;
                color: $color-grey-600;
                font-weight: 400;
            }
        }

        &__btn {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            padding: 12px 12px 12px 0;

            &__warning-text {
                margin-bottom: 14px;

                .MuiTypography-root {
                    color: rgb(211, 47, 47);
                    font-size: 13px;
                    font-style: italic;
                }
            }

            &__action {
                width: 100%;

                @media (max-width: 768px) {
                    .MuiButtonBase-root {
                        width: 100%;
                    }
                }
            }
        }
    }
}