.jugador-fixtures-summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__fixture {
        display: flex;
        height: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid $color-border;
        width: 100%;
    }

    &__fixture-left {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__fixture-center {
        width: 60%;
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__fixture-right {
        width: 20%;
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__progress-minutes {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__progress {
        position: absolute;
        left: 0;
        height: 100%;
    }

    &__progress-bar {
        border-radius: 4px;
        animation: grow 4s ease-in-out 1;
    }
}

@keyframes grow {
    0% {
        width: 1%; /* Ancho inicial del 1% al inicio de la animación */
    }
    100% {
        width: 100%; /* Ancho del 100% al final de la animación */
    }
}

.update-player-modal {
    .MuiPaper-root {
        width: 600px;

        @media screen and (max-width: $breakpoint-md) {
            width: 90%;
        }
    }
}

.master-player-card {
    .MuiDialogContent-root {
        padding: 0;
    }
}

.lanzadores-penaltis {
    .pager {
        display: none;
    }

    h1 {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: bold;
    }

    .pagination-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        span {
            font-size: 16px;
        }
    }

    .no-data {
        padding: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        text-align: center;
    }

    #lanzadores-de-penaltis {
        table {
            width: 100%;
            border-collapse: collapse;
            border: none;
            border: 1px solid rgba(0, 0, 0, 0.1);

            thead {
                tr {
                    height: 35px;
                    th#yw1_c0 {
                        padding-left: 8px;
                    }

                    th {
                        font-size: 16px;
                        text-transform: capitalize;

                        @media screen and (max-width: $breakpoint-sm) {
                            font-size: 14px;
                        }
                    }
                }
            }

            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:hover {
                cursor: default;
            }

            th,
            td {
                text-align: center;
            }

            th {
                background-color: #f4f4f4;
                font-weight: bold;
            }

            th {
                &:first-child {
                    text-align: left;
                }
            }

            .inline-table {
                border: none;
                td {
                    &:first-child, // Jugadores columna
                    &:nth-child(2) {
                        text-align: left;
                    }
                }

                tr {
                    border-bottom: none;
                    background-color: inherit;
                }

                tbody {
                    tr:nth-child(1) {
                        td:nth-child(1) {
                            width: 36px;
                            height: 28px;
                            border: none;
                            img {
                                width: 28;
                                height: 36px;
                                margin: 4px;
                            }
                        }
                    }
                }

                .hauptlink {
                    font-weight: 600;
                }
            }

            td.extrarow {
                text-align: left;
                background-color: #f9f9f9;
                padding-left: 8px;
                height: 40px;
                font-size: 16px;
            }
        }
    }
}

.filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .filter-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            margin-bottom: 5px;
            font-weight: bold;
        }

        select {
            padding: 5px;
            font-size: 16px;
            border: 1px solid #ddd;
            border-radius: 4px;
        }
    }
}
