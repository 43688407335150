.calendar-competitions {
    .content {
        display: flex;
        flex-direction: column;

        .rbc-calendar {
            min-height: 800px;
        }

        .rbc-toolbar {
            flex-wrap: nowrap;

            @media (max-width: 390px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .rbc-toolbar-label {
                display: flex;
                justify-content: flex-end;
                padding: 0;
            }
        }

        .rbc-event {
            background-color: transparent;

            .rbc-event-content {
                border-radius: 3px;
            }
        }

        .competitions-content {
            padding: 0;
            height: 32px;
            display: flex;
            align-items: center;
            gap: 4px;
            justify-content: center;

            @media (max-width: 767px) {
                justify-content: center;
            }

            .competitions-image {
                background-color: $font-primary;
                border-radius: 50%;
                padding: 2px;
            }

            .competitions-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
        }

        .calendar-competitions-legend-container {
            margin-top: 8px;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .calendar-competitions-content {
                display: flex;
                gap: 8px;

                .calendar-competitions-color {
                    width: 40px;
                    height: 24px;
                    border-radius: 2px;
                }
            }

            .competitions-content {
                padding-left: 8px;
            }
        }
    }
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
    padding: 0 !important;
}
.calendar-competitions-modal {
    .MuiPaper-root {
        width: 500px !important;
        height: 650px !important;

        .MuiDialogContent-root {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 4px;
        // border: 1px solid rgba($color: #000000, $alpha: 0.1);
        box-shadow: $box-shadow-main;
        padding-left: 5px;
        padding-right: 5px;
    }

    &__league {
        padding: 8px;
        border-bottom: 1px solid $color-grey-50;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
    }

    &__teams {
        display: flex;
        gap: 16px;
        width: 100%;
        justify-content: center;
        gap: 4px;
    }

    &__team {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 70px);
        padding-right: 4px;
    }

    &__name {
        font-size: 14px;
        white-space: nowrap;
    }

    &__results {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        gap: 10px;
    }

    &__result {
        width: 30px;
        height: 30px;
        border: 1px solid hsl(265, 7%, 70%);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__see-match {
        padding: 8px;
        border-top: 1px solid $color-border;
        width: 100%;
        margin-bottom: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.rbc-month-row {
    overflow: visible !important;
}

.calendar-convocatorias-modal {
    .MuiPaper-root {
        width: 500px;
    }

    @media screen and (max-width: $breakpoint-sm) {
        .MuiPaper-root {
            width: 95%;
            margin: 0;
        }
    }
}
