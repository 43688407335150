.convocatorias-select {
    @include flexbox(space-between, center, row);
    margin-bottom: 16px;

    .football-stats-selector {
        width: 300px;
    }

    @media screen and (max-width: 991px) {
        @include flexbox(space-between, center, column);
        gap: 8px;

        .football-stats-selector {
            width: 100%;
        }
    }

    .football-stats-selector {
        margin-bottom: 0;
    }
}

.convocatorias-table {
    margin-bottom: 16px;
}

.convocatorias-button-save {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media screen and (max-width: 991px) {
        .MuiButtonBase-root {
            width: 100%;
        }
    }
}

.convocatorias-button-publish {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    @media screen and (max-width: 991px) {
        .MuiButtonBase-root {
            width: 100%;
        }
    }
}

.convocatorias-modal {
    .MuiPaper-root {
        width: 500px !important;

        .MuiDialogContent-root {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .MuiTypography-root {
        font-style: italic;
    }
}

.convocatorias-published {
    &__players {
        display: flex;
        flex-direction: column;
    }

    &__player {
        display: flex;
        align-items: center;
        height: 70px;
        border-radius: 4px;
        box-shadow: $box-shadow-main;
    }

    &__player-col {
        display: flex;
        align-items: center;
    }

    &__text {
        margin: 0;
        font-size: 14px;
    }

    &__content {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__header {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.convocatoria__image-box {
    width: 110px;

    @media screen and (max-width: $breakpoint-md) {
        width: 20%;
    }

    p {
        @media screen and (max-width: $breakpoint-md) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
