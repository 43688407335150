$modal-height: 80%;

.lineup-selector-container {
    padding-left: 5px;
    padding-right: 5px;

    &__semaforo {
        position: absolute;
        top: 5px;
        left: 5px;
        background: #fff;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
    }

    &__semaforo-recambio {
        border: none;
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
    }

    &__recomendaciones {
        position: absolute;
        top: 0px;
        right: 5px;
    }

    &__recomendaciones-alternatives {
        top: -2px;
        right: 0px;
    }

    &__name-alternative {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__formation {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .football-stats-selector {
            width: 100%;
        }
    }

    &__team {
        background: url('https://assets.analiticafantasy.com/estadio/estadio.png?width=800');
        background-size: 100% 100%;
        width: 100%;
        height: 700px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding: 20px 5px 25px 5px;

        &__total {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            top: 5px;
            right: 15px;
            color: #fff;

            &__text {
                margin-right: 5px;
            }
        }

        &__line {
            display: flex;
            justify-content: center;
            align-items: center;

            &__player-card {
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;

                &__image {
                    border-radius: 2px;
                }

                &__name {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    background: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border-radius: 2px;
                    color: #000;
                    text-align: center;
                    height: 20px;
                    padding-top: 2px;
                    padding-left: 2px;
                    padding-right: 2px;
                }

                &__score {
                    position: absolute;
                    top: -5px;
                    left: -6px;
                    background: #1f6cb4;
                    color: white;
                    border-radius: 4px;
                    font-size: 12px;
                    width: 25px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &__team-image {
                    position: absolute;
                    top: -5px;
                    right: 0px;
                }

                &__alternative {
                    font-size: 14px;
                    white-space: nowrap;
                    text-align: center;
                    color: #fff;
                    border-radius: 4px;
                    min-width: 70px;
                }

                &__alternatives {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }

    &__bench {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-bottom: 25px;
        margin-top: 25px;
        color: #000;
        box-shadow: 0 1px 2px 1px #e1e1e1;
    }

    &__bench-text {
        display: flex;
        width: 100%;
        background: #f9f9f9;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 600;
        padding-bottom: 2px;
        padding-left: 10px;
    }

    &__bench-line {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    }
}

.lineup-selector-modal {
    padding-left: 5px;
    padding-right: 5px;
    height: 80%;

    &__header {
        padding: 5px 0 5px 5px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            padding-bottom: 10px;
            padding-top: 5px;

            &__text {
                margin: 0;
                padding: 0;
                font-size: 18px;
                font-weight: 400;
            }
        }

        &__bottom {
            &__search {
                &__input {
                    border-radius: 4px;
                    // border: 1px solid rgba($color: #000000, $alpha: 0.3);
                    padding-left: 7px;
                    font-size: 14px;
                    height: 36px;
                    width: 220px;
                }
            }
        }

        &__last {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-top: 10px;

            &__text {
                font-size: 14px;
                position: relative;
                top: 2px;
            }
        }
    }

    &__body {
        height: 600;
        overflow-y: auto;

        &__row {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            padding: 8px;

            &__team {
                width: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                gap: 7px;
            }

            &__player-image {
                width: 60px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__player-info {
                width: calc(100% - 90px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 5px;

                &__left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                    &__name {
                        margin: 0;
                        padding: 0;
                        font-weight: 400;
                        font-size: 14px;
                    }

                    &__price {
                        margin: 0;
                        padding: 0;
                        font-weight: 400;
                        font-size: 14px;
                        font-weight: 700;
                        padding-right: 8px;
                    }
                }

                &__right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;
                    padding-right: 5px;

                    &__average {
                        font-size: 14px;
                    }

                    &__points {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        height: 40px;
    }

    &__drawer {
        .MuiPaper-root {
            width: 600px;

            @media screen and (max-width: $breakpoint-md) {
                width: 90%;
            }
        }

        div.MuiPaper-root.MuiDrawer-paperAnchorRight,
        div.MuiPaper-root.MuiDrawer-paperAnchorLeft {
            top: 0 !important;
        }
    }
}

.alineaciones-probables__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
    }

    &__last-lineup {
        height: 36px;
        text-transform: none;
        min-width: 158px;
        width: 100%;

        @media screen and (max-width: $breakpoint-sm) {
            width: 100%;
        }
    }

    &__save-lineup {
        height: 36px;
        text-transform: none;
        min-width: 158px;
        width: 100%;

        @media screen and (max-width: $breakpoint-sm) {
            width: 100%;
        }
    }
}

.teams-progress-table {
    &__team-name {
        text-align: center;
        font-size: 16px;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: $breakpoint-sm) {
            font-size: 14px;
        }
    }

    &__percentage-cell {
        &__percentage {
            color: #fff;
            width: 42px;
            height: 24px;
            border-radius: 4px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
        }

        &__percentage--green {
            background: $color-jornada-green;
        }

        &__percentage--red {
            background: $color-jornada-red;
        }

        &__percentage--orange {
            background: $remember-color;
        }
    }
}

.lineup-selector-container__team__line__player-card__score-icons {
    position: absolute;
    top: 0px;
    right: 6px;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lineup-container.mobile {
    @media screen and (min-width: $breakpoint-xl) {
        display: none;
    }
}

.lineup-container.desktop {
    @media screen and (max-width: $breakpoint-xl) {
        display: none;
    }
}

.lineup-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    overflow-x: hidden;

    &__home {
        width: calc(50% - 10px);

        @media screen and (max-width: $breakpoint-xl) {
            width: 100%;
        }
    }

    &__away {
        width: calc(50% - 10px);

        @media screen and (max-width: $breakpoint-xl) {
            width: 100%;
        }
    }

    @media screen and (max-width: $breakpoint-xl) {
        margin-bottom: 40px;
        flex-direction: column;
    }
}

.equipo-lineup {
    &__field-2,
    &__field-3 {
        gap: 10%;
    }

    &__field-4,
    &__field-5 {
        justify-content: space-around;
    }
}

#lineup-field{
    margin-top: 8px;
}